import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../../../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import{UploaddescriptionN1Component}from '../../uploaddescriptionN1/uploaddescriptionN1.component';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  form: FormGroup;
  constructor(public dialog: MatDialog,private _formBuilder: FormBuilder, private LoginService: LoginService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      Name: ['', Validators.required],
      OfficerName: ['', Validators.required],
      CompanyName: ['', Validators.required],
      Speciality: ['', Validators.required],
      Address: ['', Validators.required],
      test: [false, Validators.required],
      Address2: [''],
      Email: ['', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      Phone: ['', Validators.required]

    });

  }

  onSubmit() {
   // alert(this.form.controls['test'].value);
    if (this.form.controls['test'].value){
      if (this.form.valid) {
        let a = {
          ID: '0',
          Name: this.form.controls['Name'].value,
          OfficerName: this.form.controls['OfficerName'].value,
          CompanyName: this.form.controls['CompanyName'].value,
          Speciality: this.form.controls['Speciality'].value,
          Address: this.form.controls['Address'].value,
          Address2: this.form.controls['Address2'].value,
          Email: this.form.controls['Email'].value,
          Password: '',
          Phone: this.form.controls['Phone'].value,
          flag: '0',
        }
        this.LoginService.insertuser(a).subscribe(useri => {
          if (useri=='The email is already exist')
          alert('This email address is already registered');
          else{
            console.log('test',useri);
            alert('Thank you for submitting your information. Please check your email to find an activation link and complete your registration');
          this.router.navigate(['/auth/signin/-1']);
          }
        });
      }
    }
    else
    alert('Please accept our Terms and Conditions and fill the mandatory fields')
    //  alert('Edo')
  }
  open()
  {
    const dialogRef = this.dialog.open(UploaddescriptionN1Component, {
      width: '85%',
      height: '80%',
      
      data: { answer: '', answer1: '' }
    });
  }
}
