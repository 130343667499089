import { Component, OnInit,Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ModalData {
  answer: string;
  answer1: string;
}
@Component({
  selector: 'app-uploaddescriptionN',
  templateUrl: './uploaddescriptionN.component.html',
  styleUrls: ['./uploaddescriptionN.component.scss']
})
export class UploaddescriptionNComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploaddescriptionNComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
  //this.data.answer='0';
 //   console.log(this.data.answer);
    this.dialogRef.close();
  }

}
