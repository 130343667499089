<div class="main-banner">



    <div class="test12">
      
        <p class="services1">Services</p>
        <p class="services2">Find out more about the ways we can enhance your clinical practice

        <div class="spacebtn">
            <br> <br><br> <br><br> <br><br> <br><br> <br><br><br><br><br><br><br><br>
        </div>
        <div>
            <div class="elementt2">
                <img class="at3" src="assets/imgs/Ioanna Apostolidou.jpg" />
                <div class="at4">
                    <div class="aboutspace"><br><br></div>
                    <p style="color: white; font-weight: 700;">Dr Ioanna Apostolidou</p>
                    <p style="color: white;">Periodontist, Implantologist                        
                    </p>
                    <p style="color: white;"> <a href="http://eversmileclinic.com/en/home/">  http://eversmileclinic.com/en/home/ </a></p>
                    
                    <p class="tt1">'Since I started working with
                        ExMachina, every time I place implants without a surgical guide I 'regret' it afterwards! I
                        wonder how we managed surgeries before 3D planning technology!
                        I now actually enjoy the planning process as much as the surgical procedure. Implant placements
                        are safer, more accurate and faster, which makes my patients more comfortable during the
                        operation but afterwards as well! The referring dentists and the dental technicians also have a
                        more predictable and straightforward job to do.
                        So even though I admit being sceptical initially, I now recommend it 100%!

                    </p>
                </div>


            </div>

        </div>

        <div class="spacet1">
            <br> <br> <br> <br> <br> <br> <br> <br><br><br> <br><br><br> <br><br><br> <br><br>
            <br> <br><br> <br><br> <br><br> <br><br> <br><br> <br>
        </div>
        <div>
            <div class="elementt1">
                <img class="at1" src="assets/imgs/Zoe Nikolaou.png" />

                <div class="at2"><br><br><br><br><br>
                    <p style="color: white;font-weight: 700;">Dr Zoe Nicolaou</p>
                    <p style="color: white;">Facial Cosmetic Surgery, Maxillo-Facial-Surgery, Oral - Maxillo-Facial
                        Surgeon
                     </p>
                      <p style="color: white;"> <a href=" https://ccmfc.com.cy/">  https://ccmfc.com.cy/ </a></p>

                    
                    <p class="tt1">
                        “Α valuable and trustworthy partner when it comes to complex Orthognathic and implantology
                        cases. A very professional team mastering the 3D virtual surgery planning. The entire team works
                        tirelessly to deliver high quality work for optimal results and they provide a truly exceptional
                        support. I highly recommend Ex Machina for 3D medical modelling.”



                    </p>
                </div>
            </div>


        </div>

      
        <div class="spacebtn">
            <br> <br><br> <br><br> <br><br> <br><br> <br><br><br><br><br><br><br><br>
        </div>
        <div>
            <div class="elementt4">
                <img class="at7" src="assets/imgs/Afroula Karamani.webp" />
                <div class="at8">
                    <div class="aboutspace"><br><br></div>
                    <p style="color: white; font-weight: 700;">Dr Afroula Karamani</p>
                    <p style="color: white;">Dental Surgeon, Implants & Cosmetic Dentistry
                    </p>
                    <p style="color: white;"> <a href=" https://paphosdentalclinic.business.site/">   https://paphosdentalclinic.business.site/ </a></p>
                       
                       

                  
                    <p class="tt1">'3D planning and surgical navigation
                        for implant cases are excellent tools for a dental surgeon. They enhance treatment planning and
                        reduce the risk of surgical or prosthetic complications. This technology can also be used for
                        diagnostic purposes, with excellent results. I am very pleased with ExMachina 3D Medical
                        Modeling as their services allow me to treat some very difficult cases with great success. All
                        of this would not have been possible without the excellent support of the Ex Machina team.

                    </p>
                </div>


            </div>

        </div>

        <div class="spacebtn">
            <br> <br><br> <br><br> <br><br> <br><br> <br><br><br><br><br><br><br><br>
        </div>
        <div>
            <div class="elementt3">
                <img class="at5" src="assets/imgs/Eleni Aristodimou.jpg" />

                <div class="at6"><br><br><br>
                    <p style="color: white;font-weight: 700">Dr Eleni Aristodemou</p>
                    <p style="color: white;">Periodontist, Implantologist
                    </p>
                    <p style="color: white;"> <a href=" http://www.gumsimplants.com/">  http://www.gumsimplants.com/ </a></p>
                       

                  
                    <p class="tt1">
                        '3D planning and navigation technology allows me to place implants with millimetre accuracy and
                        twice as quickly. This renders the whole experience much better and more comfortable both for
                        the patient and for me. I have also observed a dramatic decrease in the need for bone grafting,
                        with all the associated benefits.
                        Ex Machina is a very professional team, and they always deliver on time!'



                    </p>
                </div>
            </div>


        </div>

        

    </div>
</div>