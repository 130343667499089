<div class="aboutmain" style="height: auto;">


    <div class="test8">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <div class="aboutspace"> <br><br><br><br> <br><br></div>
        <p class="whyus2">About ExMachina 3D Medical Modeling</p>
        <p class="whyus3">Established in 2018, we are the first service in Cyprus specializing in 3D surgical simulation
        </p>
        <div class="cards1">
            <div class="card1">
                <hr style="height: 1px;background-color: white;width: 99%;  ">
                <p style="text-align: left; font-size: 17px;font-weight: 900;;color: white;"><b>Our Expertise</b> </p>
                <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">We are working with 3D
                    imaging,
                    3D planning and 3D printing technologies for over a decade, as researchers, clinicians and
                    innovators. </p>
                <br>
                <hr style="height: 1px;background-color: white;width: 99%; ">
                <p style="text-align: left; font-size: 17px;font-weight: 900;;color: white;">
                    Our Commitment</p>
                <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6;width: 100%;">
                    We aim to achieve the best
                    for you and your patients: as digital technologies evolve rapidly, so do we!
                </p>
            </div>
            <div class="card1">
                <hr style="height: 1px;background-color: white;width: 99%;  ">
                <p style="text-align: left; font-size: 17px;font-weight: 900;;color: white;">Our Philosophy </p>
                <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">We believe state of the art
                    technology should be
                    accessible to everyone, for the benefit of all
                    patients.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <br>
                <hr style="height: 1px;background-color: white;width: 99%; ">
                <p style="text-align: left; font-size: 17px;font-weight: 900;;color: white;">
                    Our Experience
                </p>
                <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6;width: 100%;">
                    We have successfully completed over 300 cases in Cyprus


                </p>
            </div>
        </div>
    </div>

    <div class="test9">
        <div class="aboutspace">
            <br> <br> <br> <br> <br> <br> <br> <br><br>
        </div>

      

        <br> <br><br> <br><br> <br><br> <br><br> <br><br> <br><br><br> <br><br><br> <br>
        <div>
            <div class="element">
                <img class="a1" src="assets/imgs/arto.webp" />
                <div class="a2">                   
                    <p style="text-align: left; font-size: 19px;font-weight: 900;;color: white;"><b>Dr Andreas Artopoulos</b></p>
                    <p style="color: white;line-height: 0.4;">Director & Lead Designer</p>
                    <br>

                    <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">Andreas has been invested in 3D planning, 3D imaging and 3D printing since 2008. He
                        has developed novel clinical applications of digital technologies in Dentistry, Maxillofacial
                        Surgery and Facial Prosthetics, he has presented his work in 23 international conferences and he
                        has received 10 awards and research grants by the European Prosthodontic Association, the
                        International Society for Maxillofacial Surgery, the British Society of Prosthodontics and the
                        Institute of Maxillofacial Prosthetists & Technologists, among others. Since 2012 he has also
                        been involved in teaching undergraduate and postgraduate students at King's College London and
                        more recently at the European University Cyprus.

                    </p>
                </div>


            </div>

        </div>
        <div class="aboutspace">
            <br><br><br><br><br><br>
        </div>
        <div>
            <div class="element1">
                <img class="a3" src="assets/imgs/dimitris.webp" />

                <div class="a4">
                  <p style="text-align: left; font-size: 19px;font-weight: 900;;color: white;"><b>Mr Dimitris Neofytou</b></p>
                    <p style="color: white;line-height: 0.4;">Chief Technical Officer

                    </p>
                    <br>
                    <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">
                        Dimitris is a pioneer in Dental Technology CAD/CAM in Cyprus, having established the first fully
                        digital laboratory in 2016. He is very experienced in the design and production of various types
                        of appliances and prostheses in plastic, ceramic and metal using 3D printing and CNC milling.
                        Dimitris is driven by innovation and experimentation, always seeking to adopt the newest, state
                        of the art technologies available.



                    </p>
                </div>
            </div>
        </div>
        <div class="aboutspace">
            <br><br><br><br><br><br>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div>
            <div class="element3">
                <img class="a7" src="assets/imgs/bknew.png" alt="https://en.wikipedia.org/wiki/Deus_ex_machina#/media/File:Medea_rappresentation_(2009)_07.JPG"/>
                <p class="tt1">Image from <a href="https://en.wikipedia.org/wiki/Deus_ex_machina#/media/File:Medea_rappresentation_(2009)_07.JPG">https://en.wikipedia.org/wiki/Deus_ex_machina#/media/File:Medea_rappresentation_(2009)_07.JPG</a></p>

                <div class="a5">
                   
                    <p style="text-align: left; font-size: 19px;font-weight: 900;;color: white;"><b>'Ex Machina'
                    </b></p>
                    <p style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">
                        Deus ex machina' (God from the machine) is an expression derived by an ancient Greek plot device
                        in theatre, later adopted by the Romans. This 'godsent' device was utilised when the plot
                        reached a dead-end, usually near the end of a play. An actor playing God was introduced 'flying'
                        into the scene as if from out of nowhere, by using ropes and pulleys or a raised platform, to
                        offer a solution allowing the drama to reach its conclusion. This plot device, much later also
                        used by writers like W. Shakespeare and H.G. Wells, was criticised even in the ancient times as
                        'an easy solution' for the writer which interfered with the 'proper' development and conclusion
                        of the plot.
                    </p>
                    ​
                    <p class="tt1" style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">
                        At the turn of the 21st century Computer Aided Design and Computer Aided Manufacturing (CAD/CAM)
                        clinical applications started becoming adopted by innovators at various centres of excellence in
                        healthcare, leading many to believe that these new technologies could eventually replace
                        conventional skills, offering 'an easy solution' to complex procedures. This has proved not to
                        be the case: as with all new technologies, CAD/CAM is a tool that compliments and enhances
                        conventional skills rather than aim to replace them. Within two decades, CAD/CAM adoption has
                        been embraced by the early majority, and we are now at the time of reaping the greatest benefits
                        in regards to cost/time effectiveness and clinical results. However, there is a steep learning
                        curve for someone new to this digital revolution and a justified hesitation regarding the
                        selection of the most suitable hardware and software, due to the huge variety on offer.
                    </p>
                    ​
                    <p class="tt1" style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">
                        This is where Ex Machina 3D Medical Modeling comes in: we believe that everyone should have
                        access to these technologies, for the benefit of all patients, regardless of their computing and
                        technical knowledge and skills.
                    </p>
                    ​
                    <p class="tt1" style="text-align: left; font-size: 17px;;color: white;line-height: 1.6">
                        We enter the stage, as if from out of nowhere, to offer you and your patients the solution from
                        the machine.

                    </p>


                </div>
            </div>


        </div>

    </div>
</div>