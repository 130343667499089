import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../../login.service';
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as FileSaver from 'file-saver';
import { YesornoComponent } from '../../yesorno/yesorno.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private _formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private LoginService: LoginService) { }
  form: FormGroup;
  form1: FormGroup;
  displayedColumns: string[] = ['ID', 'Docname', 'CaseIdentifier', 'DataProtection', 'Typeofcase', 'Date', 'Downloads', 'See', 'actions'];
  public dataSource = new MatTableDataSource<any>([]);
  @ViewChild('sort', { static: true }) sort: MatSort;
  exit() {
    this.LoginService.userlogin = [];
    this.router.navigate(['/auth/signin/-1']);
  }
  ngOnInit() {
    console.log(this.LoginService.userlogin);
    if (this.LoginService.userlogin === undefined) {
      this.router.navigate(['/']);
    }
    else if (this.LoginService.userlogin.length == 0)
      this.router.navigate(['/auth/signin/-1']);
    else {
      this.CaseIdentifier = [];
      let a = {
        Name: ''
      }
      this.CaseIdentifier.push(a);
      this.form = this._formBuilder.group({
        CaseIdentifier: ['', Validators.required],
        DataProtection: ['', Validators.required],
        Typeofcase: ['', Validators.required],
        Date: ['', Validators.required],
      });
      this.form1 = this._formBuilder.group({
        Name: ['', Validators.required],
        OfficerName: ['', Validators.required],
        CompanyName: ['', Validators.required],
        Speciality: ['', Validators.required],
        Address: ['', Validators.required],
        test: [false, Validators.required],
        Address2: ['', Validators.required],
        Email: ['', [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        Phone: ['', Validators.required],
        Password: ['', Validators.required]

      });
      this.readdoc();
      this.readclinformations();
    }
  }
  value = 0;
  fileData: File = null;
  uploadandfill: boolean = false;
  docnametoUpload: any = "";
  fileProgress(fileInput: any) {
    console.log('fileInput', <File>fileInput.target.files);
    if (this.form.valid) {

      for (let i = 0; i < fileInput.target.files.length; i++) {
        this.fileData = <File>fileInput.target.files[i];
        this.docnametoUpload += this.fileData.name + ";";
        // const formData = new FormData();
        //  formData.append('file', this.fileData);

        this.LoginService.upload(this.fileData.name, <File>fileInput.target.files[i]).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.value = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.value}%`);
              break;
            case HttpEventType.Response:
              console.log('User successfully created!', event.body);
              //this.getDocup();
              setTimeout(() => {
                if (i == (fileInput.target.files.length - 1)) {
                  console.log("docnametoUpload", this.docnametoUpload);
                  this.uploadandfill = true;
                }
                this.value = 0;
              }, 1500);
          }
        })
      }
    }
    else
      alert('Please fill the form')
    //  this.preview();
  }
  onSubmit() {
    if (this.form.valid) {
      const format = 'yyyy-MM-dd';
      const locale = 'en-US';
      let dates = new Date();
      let date = formatDate(this.form.controls['Date'].value, format, locale);
      let a = {
        "ID": 0,
        "ClientID": this.LoginService.userlogin[0].ID,
        "CaseIdentifier": this.form.controls['CaseIdentifier'].value,
        "DocName": this.docnametoUpload,
        "DataProtection": this.form.controls['DataProtection'].value,
        "Typeofcase": this.form.controls['Typeofcase'].value,
        "Date": date,
        "Downloads": '0',
        "See": '0',
      }
      this.LoginService.insetdoctodatabase(a).subscribe(useri => {
        alert('Successful');
        this.form = this._formBuilder.group({
          CaseIdentifier: ['', Validators.required],
          DataProtection: ['', Validators.required],
          Typeofcase: ['', Validators.required],
          Date: ['', Validators.required],
        });
        this.uploadandfill = false;
        this.readdoc();
      });
    }
  }
  CaseIdentifier = [];
  cases = [];
  readdoc() {
    this.CaseIdentifier = [];
    this.cases = [];
    this.LoginService.returnClientDoc(this.LoginService.userlogin[0].ID).subscribe(useri => {
      var response1 = JSON.parse(useri.toString());
      console.log(response1);
      this.dataSource = response1;
      this.dataSource.sort = this.sort;
      let test = this.uniqueByKey(response1, 'CaseIdentifier');
      for (let i = 0; i < test.length; i++) {
        let a = response1.filter(x => x.CaseIdentifier === test[i]['CaseIdentifier']);
        console.log('a', a);
        this.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.data = a;
        let b = {
          'Name': test[i]['CaseIdentifier'],
          'datasource': this.dataSource
        };

        this.CaseIdentifier.push(b);
        if (i == (test.length - 1))
          console.log(' this.CaseIdentifier', this.CaseIdentifier);
      }

    });
  }
  uniqueByKey(array, key) {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }
  delete(row) {
    const dialogRef = this.dialog.open(YesornoComponent, {
      width: '250px',
      height: '200px',
      data: { answer: 'Yes', answer1: 'No',answer2:'Are you sure you want to delete?' }
    });
    dialogRef.afterClosed().subscribe(res => {
      //alert(res);
      if (res == 'Yes') {
        this.LoginService.deletedoc(row.ID, this.form1.controls['Name'].value, row.Docname).subscribe(useri => {
          //  this.fillCaseDetailsCases();
          this.readdoc();
        });
      }
    });
  }
  dowload(row) {
    let cn = Number(row.Downloads) + 1;
    // alert( row.Docname.toString());
    this.LoginService.downloadfile(row.ID).subscribe(useri => {
      let splitTest = row.Docname.toString().split('.');
      let par = this.getMIMEtype(splitTest[1]);
      console.log(par);
      // let byteArray = new Uint8Array(useri,useri.byteLength);
      let blob = new Blob([useri], { type: par });
      FileSaver.saveAs(blob, row.DocumentName);
      console.log(useri);
      this.LoginService.updatedowdoc(row.ID, cn, this.form1.controls['Name'].value, row.Docname).subscribe(useri => {
        this.readdoc();
      });
    });
    //  alert('dowload ' + row.ID)
  }
  getMIMEtype(extn) {
    let ext = extn.toLowerCase();
    let MIMETypes = {
      'txt': 'text/plain',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc': 'application/msword',
      'pdf': 'application/pdf',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'bmp': 'image/bmp',
      'png': 'image/png',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.ms-excel',
      'rtf': 'application/rtf',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
    return MIMETypes[ext];
  }
  readclinformations() {
    this.form1.controls['Name'].setValue(this.LoginService.userlogin[0].Name);
    this.form1.controls['OfficerName'].setValue(this.LoginService.userlogin[0].OfficerName);
    this.form1.controls['CompanyName'].setValue(this.LoginService.userlogin[0].CompanyName);
    this.form1.controls['Speciality'].setValue(this.LoginService.userlogin[0].Speciality);
    this.form1.controls['Address'].setValue(this.LoginService.userlogin[0].Address);
    this.form1.controls['Address2'].setValue(this.LoginService.userlogin[0].Address2);
    this.form1.controls['Email'].setValue(this.LoginService.userlogin[0].Email);
    this.form1.controls['Password'].setValue(this.LoginService.userlogin[0].Password);
    this.form1.controls['Phone'].setValue(this.LoginService.userlogin[0].Phone);


  }
  onSubmit1() {
    if (this.form1.valid) {
      let a = {
        ID: this.LoginService.userlogin[0].ID,
        Name: this.form1.controls['Name'].value,
        OfficerName: this.form1.controls['OfficerName'].value,
        CompanyName: this.form1.controls['CompanyName'].value,
        Speciality: this.form1.controls['Speciality'].value,
        Address: this.form1.controls['Address'].value,
        Address2: this.form1.controls['Address2'].value,
        Email: this.form1.controls['Email'].value,
        Password: this.form1.controls['Password'].value,
        Phone: this.form1.controls['Phone'].value,
        flag: '0',
      }
      this.LoginService.updateuser(a).subscribe(useri => {
        //  this.readclinformations();
      });
    }
  }
  onSubmit2() {
  //  if (this.form1.valid) {
      const dialogRef = this.dialog.open(YesornoComponent, {
        width: '350px',
        height: '350px',
        data: { answer: 'Yes', answer1: 'No',answer2:'If you press delete this will permanently delete this user account and all user data, are you sure you wish to proceed?' }
      });
      dialogRef.afterClosed().subscribe(res => {
        //alert(res);
        if (res == 'Yes') {
          this.LoginService.deleteuser(this.LoginService.userlogin[0].ID).subscribe(useri => {
            this.router.navigate(['']);
          });
        }
      });
    }
//  }
}
