<div mat-dialog-content>
    <p>Description</p>
    <mat-form-field>
        <textarea rows="10" cols="50" matInput placeholder="Name" [(ngModel)]="data.answer"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>

    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="data.answer" cdkFocusInitial>ΝΑΙ</button>
        <button mat-button [mat-dialog-close]="data.answer1">ΟΧΙ</button>
    </div>
</div>