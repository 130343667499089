import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let innerW = window.innerWidth;
    let innerH = window.innerHeight;
    console.log('w',innerW);
    console.log('h',innerH);
  }

}
