<!-- Start Login Area -->
<section class="login-area" style="background-color:white;">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="hometextS">
                <br><br><br><br>
                <h1 class="hometext1S" style="color: black;">EX MACHINA</h1>
                <h2 class="hometext2S"  style="color: black;">3D MEDICAL MODELING</h2>
                <h3 class="hometext3S"  style="color: black;">REALIZE YOUR PATIENTS' DREAMS</h3>

            </div>
            <div class="login-image">
              
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                           
                            <h3 style="color: black;">Welcome back</h3>
                            <p style="color: black;">New user? <a style="color: black;font-weight: 600;" routerLink="/auth/signup"><b>Please create an account</b></a></p>
                            <form [formGroup]="form"> 
                                <div class="form-group">
                                    <input formControlName="Username" type="email" placeholder="Your email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input formControlName="Password" type="password" placeholder="Your password" class="form-control">
                                </div>
                                
                                <button mat-button  style="background-color: lightgray;width: 100%;" type="submit" class="default-btn1" (click)="login()" ><i class="bx bxs-hot"></i>Login<span></span></button>
                                <div class="forgot-password">
                                    <a (click)="forgot()">Forgot Password?</a>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
