import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imaging',
  templateUrl: './imaging.component.html',
  styleUrls: ['./imaging.component.scss']
})
export class ImagingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
