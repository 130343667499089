import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login.service';
@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss']
})
export class JournalComponent implements OnInit {

  constructor(private LoginService: LoginService) { }
  title: any;
  ngOnInit() {
    this.title = [];
    this.LoginService.returnJournal().subscribe(useri => {
      var response1 = JSON.parse(useri.toString());
      console.log('resp',response1)
      for (let i = 0; i < response1.length; i++) {
        let a = {
          image: response1[i].image,
          title: response1[i].title,
          url: response1[i].url
        }
        this.title.push(a);
      }
    });

  }

}
