<div class="main-banner">



    <div class="testn1">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
       
        <p class="whyusn">Publications of interest</p>
       
       

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <br> <br><br> <br><br>
        <br> <br><br> <br><br>
        <br> <br><br> <br><br>
        <br> <br><br> <br><br>
        <br> <br><br> <br><br>
       
        <div *ngFor="let item of title;">
            <div class="elementtSN">
                <img class="atSn" [src]="item.image" />

                <div class="atSn1">
                    <hr style="border: none;height: 2px;color: #333;background-color: #333;">                                   
                    <p class="ttS1">
                      {{item.title}}

                    </p>
                    <p > <a style="color: black;font-weight: 500;font-size: 12px;" target="_blank" [href]="item.url">{{item.url}} </a></p>
                </div>
            </div>

            

        </div>

      
       

        

    </div>
</div>