<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="mainscreen">

        <div class="mainhometext">

            <p class="hometext1">EX MACHINA</p>
            <p class="hometext2">3D MEDICAL MODELING</p>
            <p class="hometext3">REALIZE YOUR PATIENTS' DREAMS</p>

        </div>
    </div>
    <div class="test2">
        <video class="vid" loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
            <source src="https://video.wixstatic.com/video/11062b_c18db2b1461b46f2ad31bae61009fee1/1080p/mp4/file.mp4"
                type="video/mp4">
        </video>


        <p class="videotext">The first service in Cyprus for 3D surgical planning and
                modelling </p>
    </div>
    <div class="test3">
        <div class="columns1">
            <img src="assets/imgs/Picture5.png" alt="Snow" style="width:100%">
            <br> <br> <br>
        </div>

        <div class="columns1">
            <img src="assets/imgs/Picture7.png" alt="Forest" style="width:100%">
            <br> <br> <br>

        </div>
        <div class="columns1">
            <img src="assets/imgs/Picture8.png" alt="Mountains" style="width:100%">
            <br> <br> <br>

        </div>
        <div class="columns1">
            <img src="assets/imgs/IMG_9983.JPG" alt="Mountains" style="width:100%">
            <br> <br> <br>

        </div>
    </div>
    <div class="test4">
        <div class="columns11">

            <p class="hometext4"><a style="color: black;" routerLink="/imaging" routerLinkActive="active">3D IMAGING</a></p>
        </div>
        <div class="columns11">

            <p class="hometext4"> <a style="color: black;" routerLink="/planing" routerLinkActive="active">3D PLANNING</a></p>
        </div>
        <div class="columns11">

            <p class="hometext4" > <a style="color: black;" routerLink="/analysis" routerLinkActive="active">3D ANALYSIS</a></p>
        </div>
        <div class="columns11">

            <p class="hometext4"> <a style="color: black;" routerLink="/prinitng" routerLinkActive="active">3D PRINTING</a></p>
        </div>
    </div>
    <div class="test5">
        <p class="howit"><b>How It Works</b></p>
        <div class="columns">
            <br> <br> <br> <br>
          <div class="testspace"> 
            
            <br> <br> <br> <br>
            </div>
            <mat-icon class="icon1" aria-hidden="false" aria-label="Example home icon">alternate_email</mat-icon>
            <br> <br> <br>
            <p style="text-align: center;text-decoration: overline; font-size: 17px;font-weight: 0;;color: white;">
                Tell us about your case
            </p>
            <p style class="desc1">We can assist you in a wide variety of surgical procedures.

                Let us discuss your patient's needs.
            </p>
        </div>
        <div class="columns">
            <br> <br> <br> <br>
            <div class="testspace"> 
             
             <br> <br> <br> <br>
             </div>
            <mat-icon class="icon2" aria-hidden="false" aria-label="Example home icon">file_upload</mat-icon>
            <br> <br> <br>
            <p style="text-align: center;text-decoration: overline; font-size: 17px;font-weight: 0;;color: white;">
                Upload your files
            </p>
            <p class="desc1">You can share any photos, radiographs, 3D data or any other file using our secure file
                transfer
            </p>
        </div>
        <div class="columns">
            <br> <br> <br> <br>
            <div class="testspace"> 
            
             <br> <br> <br> <br>
             </div>
            <mat-icon class="icon3" aria-hidden="false" aria-label="Example home icon">phone</mat-icon>
            <br> <br> <br>
            <p style="text-align: center;text-decoration: overline; font-size: 17px;font-weight: 0;;color: white;">
                Let's keep this simple!
            </p>
            <p class="desc1">

                Once we have all the information about your case we will contact you with an offer and the next steps
            </p>
        </div>
        <div class="columns">
            <img src="assets/imgs/test.webp" alt="Mountains" style="width:100%;height: 100%;">
        </div>
    </div>
    <div class="test6">
        <br> <br> <br> <br>
        <p class="whyus">Why Choose Us</p>
    <div class="test6space"> 
        <br> <br><br> <br>
    </div>
        <div class="columns2">
            <hr style="height: 1px;background-color: grey;width: 32%; ">
            <p style="text-align: center; font-size: 17px;font-weight: 100;;color: black;">10+ Years of Experience
            </p>
            <div class="test6space"> 
            <br> <br>
            </div>
            <hr style="height: 1px;background-color: grey;width: 32%; ">
            <p style class="desc2">GDPR compliant procedures
            </p>
        </div>
        <div class="columns2">
            <hr style="height: 1px;background-color: grey;width: 32%; ">
            <p style="text-align: center; font-size: 17px;font-weight: 100;;color: black;">State of the art equipment
            </p>
            <div class="test6space"> 
            <br> <br>
            </div>
            <hr style="height: 1px;background-color: grey; width: 32%;">
            <p class="desc2">Over 300 cases successfully treated in Cyprus
            </p>
        </div>
        <div class="columns2">
            <hr style="height: 1px;background-color: grey;width: 32%;">
            <p style="text-align: center; font-size: 17px;font-weight: 100;;color: black;">Competitive pricing
            </p>
            <div class="test6space"> 
            <br> <br>
            </div>
            <hr style="height: 1px;background-color: grey; width: 32%;">
            <p class="desc2">
                Constantly Evolving
            </p>
        </div>
    </div>
    <div class="mainscreen1">
    </div>
    <div class="space">
    </div>
</div>
<!-- End Main Banner Area -->
<!--
<app-partner></app-partner>


<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Path is here for faster way of connections with your customers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>

            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services2.png" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>The best Innovative Chatbot and automations are here to expand</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Watch this video presentation to know more</h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/6.png" alt="image"></div>
        </div>

        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="180">00</span><span class="sign-icon">k</span></h3>
                        <p>Downloaded</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span></h3>
                        <p>Feedback</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="500">00</span><span class="sign-icon">+</span></h3>
                        <p>Workers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="70">00</span><span class="sign-icon">+</span></h3>
                        <p>Contributors</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i>Contact Us<span></span></a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>The ability to elicit, assessing opportunities</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Simplify Communication</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>Drag and Drop Widgets</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Quick Setup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>Automatise Chatbots</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>Automated Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Machine Learning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-pricing-plan></app-pricing-plan>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<app-clients></app-clients>

<app-free-trial></app-free-trial>-->