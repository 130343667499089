<div class="main-banner">



    <div class="test122">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <p class="whyus2">3D Printing

        </p>
        <p class="whyus3">We use state of the art SLA technology to create anatomical models and biocompatible guides so you can transfer your treatment plan to the operating theatre.

        </p>

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <div>
            <div class="elementtS24">
                <img class="atS34" src="assets/imgs/printing1.webp" style="height: 467px;     width: 895px;" />
                <div class="atS44">
                  
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black; font-weight: 700; font-size: 21px;">Anatomical models
                    </p>
                    <p class="ttS14">When planning complex procedures, such as orthognathic surgery or oncological cases, being able to hold a real sized, accurate to the millimetre replica of your patient's anatomy can help you choose the best possible approach. The planned procedure can then be simulated on the printed model to allow visualization and manipulation of the anticipated outcome. 
                    </p>
                </div>
            </div>
        </div>

        <div class="spacet1">
            <br> <br> <br> <br>
        </div>
        <div>
            <div class="elementtS14">
                <img class="atS14" src="assets/imgs/printing2.webp" style="height: 469px;width:895px;" />

                <div class="atS24">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Surgical navigation
                    </p>
                    <p class="ttS14">
                        Having established the optimum treatment plan through the 3D simulation, all that remains is to transfer the planned procedure on to the patient! This can be achieved accurately and safely with our patient specific, biocompatible 3D printed guides. Depending on your requirements, we can design a wide variety of guides. The materials we use are certified for this purpose, can be sterilized in an autoclave and are easy to trim and adjust intraoperatively if needed, for example if access to the region of interest proves to be limited.
                    </p>
                </div>
            </div>
        </div>


        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS44">
                <img class="atS74" src="assets/imgs/printing3.gif" style="height: 486px;width: 895px;" />
                <div class="atS84">
                   
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Guided implantology
                    </p>
                    <p class="ttS14">No one likes surprises at the prosthetic stage of implant rehabilitation! Ensure your implant plan is transferred safely, quickly and accurately in the patient's mouth with a custom-made surgical guide. Avoid complications, ensure parallel placement - or at the very least be prepared in advance if this cannot be achieved! - and reduce bone grafting expenses. Guided implantology is not just a 'novelty' any more, it is evidently the best way to approach implant rehabilitation.
                    </p>
                </div>
            </div>

        </div>

        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS34">
                <img class="atS14" src="assets/imgs/printing4.webp " style="height: 471px;width: 895px;" />
                <div class="atS64">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">We can even 3D print what you cannot see!
                    </p>
                    <p class="ttS14">
                        Sometimes what is of interest is that which has not been imaged. Air or fluid filled cavities, cysts, tumours, soft tissues may not be imaged on a CT scan, but we may still be able to apply advanced segmentation and derive a 3D model for fabrication.
                    </p>
                </div>
            </div>
            <br><br><br>
        </div>



    </div>
</div>