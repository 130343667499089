import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ModalData {
  answer: string;
  answer1: string;
}
@Component({
  selector: 'app-uploaddescription',
  templateUrl: './uploaddescription.component.html',
  styleUrls: ['./uploaddescription.component.scss']
})
export class UploaddescriptionComponent implements OnInit {
  panelOpenState = false;

  text1 = [
    {
      "Title": '1. I would like to get involved with guided implantology but I am not sure where to start!',
      "Text": 'The first thing to do is select the right case. Guided implantology is indicated for all cases, from a single implant restoration to complex full-arch cases involving sinus lift procedures, but choosing a simple case as your first guided case will help you familiarize yourself with the procedures involved with minimal disruption to your regular workflow. Also, if you choose a case with a small number of implants (up to 3 implants) it will not cost you anything! To help you get started, we offer your first 3D virtual surgical plan and surgical guide (case with up to 3 implants) free of charge, without any further commitment!'
    },
    {
      "Title": '2. Should I first refer the patient for a 3D CBCT scan and forward it to you?',
      "Text": 'No! In some cases there is a need to use a radiographic guide during the 3D CBCT scan to enable accurate registration of the dental scans to the CBCT scan, for example if there is a very small number of remaining teeth or if there are extended metal restorations. The first thing to do is obtain good quality, correctly extended study casts and articulate these. Depending on the number and positions of missing teeth, you may need record bases with wax occlusion rims to do this. A diagnostic setup or a copy denture may be required if there are too many missing teeth, in order to establish the desired tooth positions and occlusal vertical dimension and confirm these with a trial insertion. In most cases, there will be a radiograph available or one will be recorded before the 3D CBCT scan anyway. A good place to start is to forward us the radiograph and/or photographs of the study casts and we will contact you to guide you on the next steps. We will be happy to collaborate with your dental technician, or if you prefer we can provide everything you will need in collaboration with MillDent Digital Dental Laboratory.'
    },
    {
      "Title": '3. Do I always need a diagnostic setup and trial insertion?',
      "Text": 'No! Depending on the number and positions of missing teeth, as well as the occlusion, in many cases we can carry out a diagnostic setup digitally. Single implant cases rarely require a diagnostic setup (conventional or digital), unless there is a specific clinical indication, such as highly demanding cases in the aesthetic zone or cases with limited interocclusal space.'
    },
    {
      "Title": '4. Should I only use silicone impression materials to achieve more accurate impressions?',
      "Text": 'This is not necessary! Modern alginate impression materials can achieve excellent reproduction of the soft and hard tissues, but care must be taken to ensure correct extension of the impression, avoid incorporation of air voids and where there are extended edentulous areas (such as in free-end saddles) make sure the alginate is well supported. This can easily be achieved by modifying a stock impression tray with some silicone putty in the edentulous areas (if wider than 2 missing teeth) before recording an alginate wash. Use of impression tray adhesive is recommended to prevent delamination of the alginate during removal of the impression. A custom impression tray can be used but in most cases it is not necessary. Always follow the impression material manufacturers’ instructions of use in regards to powder/water ratio, working and setting times, disinfection and storage.'
    },
    {
      "Title": '5. I recorded impressions but they are not perfect. Should I repeat this stage?',
      "Text": 'Correctly extended and accurate impressions without any major flaws are required for the design and fabrication of the surgical guide. Please keep in mind that after virtual planning is completed the surgical guide is designed on the scan of the dental cast, so if the impression is not of good quality this will impact on the fitting and accuracy of the surgical guide. If you are unsure whether the impression is acceptable we can assess it for you before we proceed. '
    },
    {
      "Title": '6. Can I use an intraoral scanner?',
      "Text": 'Yes! Just please make sure to record the whole width and extent of the arch, as well as the sulci in the edentulous area(s). Also scan the opposing dentition and the jaw relationship in maximum intercuspation position so we can digitally articulate your scans. Your surgical guide will be delivered on a 3D printed cast at no extra cost!'
    },
    {
      "Title": '7. Can I use a triple tray?',
      "Text": 'No! Even in single implant cases it is best to record the whole dental arch, so the surgical guide can be extended to the contralateral side to achieve cross arch stabilization. Even if this may not be required, we still need the whole dental arch recorded in the impression to ensure accurate registration of the dental scan to the 3D CBCT scan.'
    },
    {
      "Title": '8. While we’re at it, could I prescribe a sectional 3D CBCT scan if I am only planning implants on one quadrant?',
      "Text": 'Not if you wish to proceed with guided placement! Even though 3D virtual surgical planning can still be done on sectional scans, we need the whole dental arch imaged so we can ensure an accurate registration of the dental scan onto the 3D CBCT scan, a necessary step for the production of the surgical guide.'
    },
    {
      "Title": '9. I already have a 3D CBCT scan of my patient’s jaw. Can you use it?',
      "Text": 'In many cases we can! Even if some teeth have been extracted since the 3D CBCT scan was recorded, we can digitally ‘remove’ them and proceed with your 3D plan and surgical guide fabrication. But it is your clinical decision to make whether the existing scan will accurately represent the current bone levels in the area of interest. In general 3D CBCT scans recorded up to 6 months earlier can be safely used unless there are clinical reasons to suspect accelerated bone resorption in the area of interest. Still, we could take this into consideration while finalising your treatment plan, so it is best to let us assess the existing 3D scan before prescribing a new one. However, we might not be able to use existing 3D CBCT scans if there has been restorative work done on the dentition since the scan was recorded, as this may render accurate registration of the dental casts onto the 3D CBCT scan impossible.'
    },
    {
      "Title": '10. How about a pre-existing study cast?',
      "Text": 'Again, in many cases we can use an existing study cast, unless you have clinical reasons to suspect tooth movements may have occurred since the cast was fabricated, or if there has been restorative work done. If that is the case, the surgical guide we will produce will fit accurately on the cast but may not fit correctly in the patient’s mouth! If some teeth have been extracted this is in most cases not a problem as we can digitally ‘remove’ them from the scan of the dental cast. We can then modify the cast to deliver the surgical guide seated, or we can 3D print a modified cast at no extra cost.'
    },
    {
      "Title": '11. Who decides the implant positions and who will have the responsibility if something goes wrong?',
      "Text": 'Once you have approved the diagnostic tooth setup we will plan your implant case according to your prescription, following the principles of prosthetically driven implantology. The final implant positions must be approved by you as the clinician as all responsibility will ultimately lie with you. If the case is not suitable for implant placement in the prescribed locations we will not proceed with fabrication and delivery of the surgical guide. The same is true if the impression or the 3D CBCT scan are of poor quality. All cases are thoroughly assessed by our clinical team, but we can only be responsible for ensuring an accurate fitting of the surgical guide onto the dental cast.'
    },
    {
      "Title": '12. Could you also prepare the provisional restoration for immediate loading?',
      "Text": 'Provided the implants are placed using the surgical guide with all the drills and guided implant carrier, in order to ensure the planned locations are transferred accurately in the mouth, it is possible to also prepare a provisional restoration. There is also a possibility to prepare other types of accessories, such as custom trays for the secondary impressions prior to the definitive restoration. Please contact us so we can discuss all the options for your case!'
    },
    {
      "Title": '13. Is it possible to perform flapless surgery using your guides?',
      "Text": 'We can produce any kind of surgical guide you require, either conventional, semi-guided or fully-guided. However, in most cases we would recommend semi-guided placement (using a flap) to allow visual inspection of the surgical site during the procedure.'
    },
    {
      "Title": '14. Do I need to buy a special surgical kit and metal sleeves?',
      "Text": 'No you don’t! Unless you prefer to use your own guided surgery kit, which is entirely feasible, we can provide you with our universal guided surgery kit which does not require any metal sleeves – the guide tubes are incorporated in the design of the guide. Our kit comes with a selection of universal guided surgery drills ranging from 2mm to 2.8mm in diameter and from 7mm to 13mm length, which allow you to create the initial osteotomy. Following that, you can either remove the guide and use your own drills to finalise the osteotomy freehand – the initial osteotomy will be your guide! – or you can purchase single use guided surgery drills for your case.'
    },
    {
      "Title": '15. Which implant systems are supported by your planning software and the universal guided surgery kit?',
      "Text": 'Our planning software is loaded with complete implant libraries from 54 manufacturers (including AlphaBio, Bicon, Biohorizons, MIS, Megagen, Nobel, Noris Medical, Paltop, Southern Implants and Straumann among others) with new ones being added regularly. Even if your implant system is not already included, the software allows us to design custom implants, so all we need to know are the implant length and diameter at the apical and occlusal ends. The universal guided surgery kit can be used with any implant system. Once you prepare the initial osteotomy you can either remove the surgical guide and finalise the osteotomy freehand with your own drills, or you can purchase single use guided surgery drills.'
    },
    {
      "Title": '16. How much does all of this cost? ',
      "Text": 'Less than you think! Prices start from as low as 150 euros for single implant cases, and this includes the digitization of dental casts or impressions, articulation, digital tooth setup, virtual surgical planning and 3D printed surgical guide. As soon as we have all the information required about your case we will provide you with an exact quotation for your approval. The cost savings you will enjoy due to reduction of operating time, reduced needs for bone grafting and more straightforward and predictable prosthetic procedures more than make up for the cost of virtual planning and surgical guide! In our clinical experience (and our partners’) implant patients are more than happy to accept these costs when all the benefits of guided implantology are conveyed to them. And the best part? We feel so confident that you will become one of our partners that we offer you your first 3D virtual surgical plan and surgical guide for a case with up to 3 implants for free, without any further commitment, so you can try for yourself! '
    },
    {
      "Title": '17. Ok, I am convinced! What kind of information will you need?',
      "Text": 'Please contact us to discuss your case and we will email you a prescription form so you can fill in all required information.'
    },

  ];
  text2 = [
    {
      "Title": '1. What technology is used in facial imaging?',
      "Text": 'We employ state of the art 3D digital stereophotogrammetry to capture accurate 3D images of the face and body. This is a non-invasive, optical imaging technique based on digital photography, which is ideal for soft tissue imaging.'
    },
    {
      "Title": '2. How does it work?',
      "Text": 'Our 3D digital stereophotogrammetry device captures multiple high resolution digital photographs simultaneously under different angles using standardized studio quality flash lighting. Specialised software is then used to process these and generate an accurate 3D model of the surface morphology and texture based on principles of triangulation.'
    },
    {
      "Title": '3. Why is this better than extracting soft tissue data from a 3D CT or CBCT scan?',
      "Text": 'There are several advantages in imaging the soft tissues of the face with 3D digital stereophotogrammetry. First, there is no distortion of the facial tissues as there is no need to use any positioning device on the chin or between the anterior teeth. Secondly, 3D digital stereophotogrammetry is entirely safe for the patient as it is based on photography, therefore it is feasible to acquire as many images as needed without concerns about radiation exposure. This also renders this technology ideal for imaging younger patients and for long term follow up (repeated exposures). Thirdly, the risk of motion artefacts due to changes in facial expression, body posture or breathing movements is effectively non-existent: image capture takes only 2 milliseconds! Last but not least, the quality of the surface data and textures is vastly superior to CT or CBCT due to the very high resolution.'
    },
    {
      "Title": '4. How accurate is it?',
      "Text": 'Clinical research has established that the accuracy of digital stereophotogrammetry meets clinical requirements for most surgical applications. Mean surface imaging error was below 1mm. Please navigate to the ‘Journal Articles’ section of our webpage if you wish to read more about this.'
    },
    {
      "Title": '5. How can I view or edit the 3D data?',
      "Text": 'We can provide the acquired 3D imaging data of your patient in various commonly used formats, such as OBJ and STL, so you can use any 3D CAD software of your preference or import the data to any virtual surgical planning software. Alternatively you can use Windows or MAC OS built-in 3D viewers, or we can send a you a 3D PDF file. Depending on the intended clinical application we will also provide a report.'
    },
    {
      "Title": '6. What are the clinical applications?',
      "Text": 'Diagnosis, treatment planning, simulation, long term monitoring, pre and post-operative comparisons and 3D analyses for facial cosmetic procedures, maxillofacial, craniofacial & orthognathic surgery, skin conditions, orthodontics and prosthodontics, trauma and oncology. We can capture, view, sculpt, measure & analyse skin topography, face and body contours for the most accurate and realistic assessments and patient education.'
    },
    {
      "Title": '7. Where will my patient have to go for facial imaging?',
      "Text": 'We use a portable digital stereophotogrammetry device so we can obtain the images at any setting. Standard procedure is for the patient to visit our clinic in Limassol (by appointment), but we can also arrange for a 3D photoshoot  at your premises, at one of our partners’ clinics or at a hospital setting. Please bear in mind that this will have to be pre-arranged on a case-by-case basis and will incur additional charges.'
    },
    {
      "Title": '8. How long does it take?',
      "Text": 'Not long at all! Your patient will only have to attend a brief appointment. The 3D photoshoot only takes a few minutes (to acquire written informed consent, explain the procedure and position the patient). The patient will then be asked to wait for a few more minutes while we carry out the initial processing of the acquired images to ensure they are of good quality in case a repeat is needed. The final processing of the images is done after the patient has left.'
    },
    {
      "Title": '9. How much does it cost? ',
      "Text": '3D facial imaging costs start from as little as 50 euros, comparable to a panoramic radiograph!'
    },
    {
      "Title": '10. What service options do you offer?',
      "Text": 'We offer 3 standardized services:<br>' +
        'a.	Single 3D face scan<br>' +
        'Includes PDF report of the images acquired at preselected views, additional specific views upon request and the 3D files delivered electronically as OBJ and/or STL file format, to be integrated into your own software of preference. These may also be aligned and registered to a skull 3D model and delivered together, upon request.<br>' +
        'b.	Pre and post-operative 3D face scans and 3D comparison<br>' +
        'This package includes all of the above for each of the pre and post operative images acquired, in addition to a 3D comparison and analysis of differences between the two instances.<br>' +
        'c.	Full 3D analysis and/or 3D simulation<br>' +
        'This package includes all of the above for each of the pre and post operative images acquired, in addition to a full soft tissue analysis and simulation of the surgical procedure prescribed.<br>' +

        'Further, we offer a custom service based on your specific clinical needs. Please contact us to discuss details of your planned procedure.<br>'
    },
    {
      "Title": '11. Can I view some sample 3D images?',
      "Text": 'Yes, right here on our webpage! Please navigate to ‘Services’ section. We can also forward you sample reports for each of the 3 service options listed above and/or sample 3D files acquired with our equipment – all you have to do is send us a request!'
    },
    {
      "Title": '12. Can the acquired data be used for 3D printing?',
      "Text": 'Of course! Imaging data can be exported as OBJ or STL file type so they can be edited and used with any 3D printer. There are many clinical applications for this so please contact us to discuss the specific details of your case and your clinical needs.'
    },
  ]
  text3=[
{
"Title": '1. What services do you offer?',
"Text": 'We can assist in the planning and execution of all types of maxillofacial and orthognathic cases such as mandibular resection and reconstruction, orbital floor replacement, zygomatic, maxilla expansion, maxillectomy, bimaxillary and single jaw orthognathic, cranioplasty, trauma etc.'
},
{
"Title": '2. How does it work?',
"Text": 'Depending on the case, we use Computed Tomography (CT) or Cone Beam Computed Tomography (CBCT) imaging data, as well as soft tissue imaging data and dental scans to reconstruct the anatomy of your patient in our 3D planning software. We then carry out a simulation of the planned procedure, based on your prescription. For example, fractures of the mandible can be corrected virtually by restoring the continuity of the fractured parts and/or the occlusion of the teeth. Orbital floor and/or zygomatic reconstruction is done by mirror-imaging the non-defect side onto the affected side. Orthognathic cases are planned based on the cephalometric analysis, target occlusion, symmetry and aesthetics. '
},
{
"Title": '3. How do I transfer the treatment plan accurately onto the patient?',
"Text": 'We can provide you with a variety of custom-made (3D printed) biocompatible surgical guides to ensure the simulation is transferred easily and accurately into the operating theatre, such as resection guides, grafting guides, occlusal wafers etc. We also provide life sized anatomical models of the corrected anatomy so you can pre-bend  your reconstruction plates and save valuable operating time. Soft tissue procedures are planned with the mirror imaging technique and volumetric analysis provides the exact volume of graft required to achieve the most aesthetically pleasing outcome.'
},
{
"Title": '4. How accurate is it?',
"Text": 'There is a substantial body of evidence available in the international literature that supports the use of 3D planning and CAD/CAM surgical guides for maxillofacial and orthognathic procedures. Deviation between the planned simulation and real world post-operative outcomes is reported at the millimetre level.'
},
{
"Title": '5. What are the benefits of 3D Virtual Surgical Planning?',
"Text": 'One of the fundamental principles of metrology is that one should always use an appropriate measuring instrument and strategy for each specific type of measurement. As the human anatomy is 3-dimensional, we consider it an entirely absurd proposition to treatment plan based on 2 dimensional images! In complex surgery, such as maxillofacial, being able to execute an accurate simulation of the planned procedure while taking into consideration the entire anatomy of the skull is invaluable. Increased accuracy, improved treatment outcomes, reduced risk of complications and substantially reduced operating times have been demonstrated in numerous studies published in the international literature. '
},
{
"Title": '6. Which software do you use for 3D Virtual Surgical Planning?',
"Text": 'Depending on the case and your requirements, we may use any combination of 9 software packages (CAD, dental, DICOM editors, mesh editors, soft tissue editor/sculptor). We have many years of experience with each one of those while we are always happy to try new solutions!'+
'One thing in common between all the software packages we use is that they all allow exporting of 3D data in open format, which means we can provide you with the 3D files of your case in your preferred format (e.g. OBJ, STL) so you can view or edit the data in your computer, use it during patient consultations, consent procedures or presentations and keep detailed records of your cases’ initial presenting condition, treatment simulation and post-operative outcome. '
},
{
"Title": '7. What kind of 3D printers do you use?',
"Text": 'There are 7 entirely different 3D printing technologies available, each with its own advantages and limitations. Our 3D printers of choice for maxillofacial and orthognathic applications are using SLA (Vat Photopolymerization) technology. This was the first 3D printing technology patented back in 1987; it has significantly evolved since then, its use cases expanded with new materials and various refinements and it is still considered one of the best and most accurate 3D printing techniques available. SLA technology allows us to 3D print anatomical models with a resolution (layer height) of just 25microns. In this way, the printed models present a very fine and smooth surface, similar to the actual anatomy, while extremely small details and features are preserved. Further, SLA allows us to 3D print the various types of surgical guides in biocompatible material, which can be sterilized in an autoclave and is specifically formulated and licensed for this purpose. Last but not least, the resins used in SLA can be easily trimmed and cut using rotary instruments, which allows us to perform model surgery simulation.'
},
{
"Title": '8. I have heard that SLA 3D printing is very accurate but also very expensive…',
"Text": 'SLA 3D printing is indeed one of the most accurate rapid prototyping technologies available, however the associated costs have decreased significantly over the past years due to the very fierce competition in the market. Our pricing is thus very competitive compared to similar services abroad. Please contact us for pricing information.'
},
{
"Title": '9. So, how accurate are the 3D printed models?',
"Text": 'The term ‘accuracy’ is often misused or confused with ‘resolution’. Accuracy of a 3D printed model can only be assessed in regards to its closeness to the geometry of the 3D file used to print it. In that respect, SLA models are very accurate indeed, the limiting factor being instead imaging errors of the 3D CT or CBCT scanner used, which are unavoidable when imaging a living subject. The accuracy of a 3D printed model can also be affected by many other factors, such as the shape of the object, the material used, the orientation of the model in the build platform, the design of the supporting structures and others. Our equipment allows us to fabricate models with a dimensional error of <100µm over 95% of the object volume, but this will in fact vary for every single object we 3D print. Considering the imaging errors of 3D CT/CBCT and the thickness of surgical cutting instruments, it is apparent that 3D printing errors are clinically negligible. '
},
{
"Title": '10. What are the size limitations for 3D printed anatomical models?',
"Text": 'In maxillofacial applications, we are usually asked to provide anatomical models of the facial skeleton. In most cases, we will provide an articulated mandible so it can be easily removed and repositioned to assess various treatment options. However, we can provide a model of the whole skull, and in the rare few cases this might be too large for our 3D printers it is fabricated in 2 or more pieces and assembled prior to delivery.'
},
{
"Title": '11. How is the treatment plan finalised?',
"Text": 'We will carry out a simulation based on your prescription. Once ready, we will either forward you a full report and 3D data for any necessary adjustments or corrections prior to final approval, or we will arrange a face to face or remote meeting to make any corrections/adjustments together. The production of 3D printed guides will not commence until you are fully satisfied with the treatment plan.'
},
{
"Title": '12. Who will have the responsibility if something goes wrong?',
"Text": 'The treatment plan and the design of any deliverable must be approved by you as the clinician as all responsibility will ultimately lie with you. If the case is not suitable or if the impressions or the 3D scans are of poor quality, we will not proceed with the planning. All cases are thoroughly assessed by our clinical team, but we can only be responsible for ensuring an accurate fitting of our guides onto the dental casts or anatomical models.'
},
{
"Title": '13. Can I view some completed cases?',
"Text": 'Due to patient confidentiality we cannot display any of our cases on the web. Please contact us for a demonstration.'
},
{
"Title": '14. How much does it cost?',
"Text": 'Please contact us to discuss your needs and requirements so we can provide correct pricing information.'
},

  ]
  title1: any = ''
  text: any = [];
  constructor(
    public dialogRef: MatDialogRef<UploaddescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {

    
  }

  ngOnInit() {
    console.log(this.data);
    if (this.data.answer == '1') {
      this.text = [...this.text1];
      this.title1 = 'Guided Implantology';
console.log(this.data.answer,this.text);
    }
   else if (this.data.answer == '2') {
      this.text = [...this.text2];
      this.title1 = 'Facial imaging';
      console.log(this.data.answer,this.text);

    }
    else if (this.data.answer == '3') {
      this.text = [...this.text3];
      this.title1 = 'Maxillofacial & Orthognathic';
      console.log(this.data.answer,this.text);

    }
  }

  onNoClick(): void {
    //this.data.answer='0';
    //   console.log(this.data.answer);
    this.dialogRef.close();
  }
  ngOnDestroy(){
    console.log('Eklise')
}
}
