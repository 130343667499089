import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../../login.service';
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as FileSaver from 'file-saver';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploaddescriptionNComponent } from '../../uploaddescriptionN/uploaddescriptionN.component';
import { YesornoComponent } from '../../yesorno/yesorno.component';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-features-one',
  templateUrl: './features-one.component.html',
  styleUrls: ['./features-one.component.scss']
})
export class FeaturesOneComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router, private LoginService: LoginService, public dialog: MatDialog) { }
  form: FormGroup;
  form1: FormGroup;
  displayedColumns: string[] = ['ID', 'Docname', 'CaseIdentifier', 'DataProtection', 'Typeofcase', 'Date', 'Downloads', 'See', 'actions'];
  public dataSource = new MatTableDataSource<any>([]);
  displayedColumns1: string[] = ['ID', 'Name', 'CompanyName', 'Phone', 'Email', 'actions'];
  public dataSource1 = new MatTableDataSource<any>([]);

  displayedColumns2: string[] = ['ID', 'image', 'title', 'url','OrderNum'];
  public dataSource2 = new MatTableDataSource<any>([]);
  displayedColumns3: string[] = ['ID', 'image', 'title', 'url','OrderNum'];
  
  public dataSource3 = new MatTableDataSource<any>([]);

  displayedColumns11: string[] = ['ID', 'Name', 'CompanyName', 'Phone', 'Email', 'actions'];
  public dataSource11 = new MatTableDataSource<any>([]);
  displayedColumns111: string[] = ['ID', 'Name', 'CompanyName', 'Phone', 'Email', 'actions'];
  public dataSource111 = new MatTableDataSource<any>([]);
  @ViewChild('sort', { static: true }) sort: MatSort;
  exit() {
    this.LoginService.userlogin = [];
    this.router.navigate(['/auth/signin/-1']);
  }
  ngOnInit() {
    console.log(this.LoginService.userlogin);
    if (this.LoginService.userlogin === undefined) {
      this.router.navigate(['/auth/signin/-1']);
    }
    else if (this.LoginService.userlogin.length == 0)
      this.router.navigate(['/auth/signin/-1']);
    else {
      this.form = this._formBuilder.group({
        CaseIdentifier: ['', Validators.required],
        DataProtection: ['', Validators.required],
        Typeofcase: ['', Validators.required],
        Date: ['', Validators.required],
      });
      this.form1 = this._formBuilder.group({
        Name: ['', Validators.required],
        OfficerName: ['', Validators.required],
        CompanyName: ['', Validators.required],
        Speciality: ['', Validators.required],
        Address: ['', Validators.required],
        test: [false, Validators.required],
        Address2: ['', Validators.required],
        Email: ['', [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        Phone: ['', Validators.required],
        Password: ['', Validators.required]

      });
      this.readClients();
      this.run(0);
      this.run(1);
      //  this.readdoc();
      // this.readclinformations();
    }
  }
  value = 0;
  fileData: File = null;
  uploadandfill: boolean = false;
  CaseIdentifier = [];
  readdoc(id) {
    this.CaseIdentifier = [];
    this.dataSource = new MatTableDataSource<any>([]);
    this.LoginService.returnClientDoc(id).subscribe(useri => {
      var response1 = JSON.parse(useri.toString());
      console.log(response1);
      if (response1.length != 0) {
        this.dataSource.data = response1;
        this.dataSource.sort = this.sort;

        let test = this.uniqueByKey(response1, 'CaseIdentifier');
        for (let i = 0; i < test.length; i++) {
          let a = response1.filter(x => x.CaseIdentifier === test[i]['CaseIdentifier']);
          console.log('a', a);
          this.dataSource = new MatTableDataSource<any>([]);
          this.dataSource.data = a;
          let b = {
            'Name': test[i]['CaseIdentifier'],
            'datasource': this.dataSource
          };

          this.CaseIdentifier.push(b);
          if (i == (test.length - 1))
            console.log(' this.CaseIdentifier', this.CaseIdentifier);
        }

      }
    });
  }
  uniqueByKey(array, key) {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }
  delete(row) {
    const dialogRef = this.dialog.open(YesornoComponent, {
      width: '250px',
      height: '200px',
      data: { answer: 'Yes', answer1: 'No',answer2:'Are you sure you want to delete?' }
    });
    dialogRef.afterClosed().subscribe(res => {
      //alert(res);
      if (res == 'Yes') {
        this.LoginService.deletedoc(row.ID, "Admin", row.Docname).subscribe(useri => {
          //  this.fillCaseDetailsCases();
          this.readdoc(this.clientidselected);
        });
      }
    });
  }
  dowload(row) {
    let cn = Number(row.Downloads) + 1;
    // alert( row.Docname.toString());
    this.LoginService.downloadfile(row.ID).subscribe(useri => {
      let splitTest = row.Docname.toString().split('.');
      let par = this.getMIMEtype(splitTest[1]);
      console.log(par);
      // let byteArray = new Uint8Array(useri,useri.byteLength);
      let blob = new Blob([useri], { type: par });
      FileSaver.saveAs(blob, row.DocumentName);
      console.log(useri);
      this.LoginService.updatedowdoc(row.ID, cn, "Admin", row.Docname).subscribe(useri => {
        this.readdoc(this.clientidselected);
        //   this.readdoc();
      });
    });
    //  alert('dowload ' + row.ID)
  }
  getMIMEtype(extn) {
    let ext = extn.toLowerCase();
    let MIMETypes = {
      'txt': 'text/plain',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc': 'application/msword',
      'pdf': 'application/pdf',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'bmp': 'image/bmp',
      'png': 'image/png',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.ms-excel',
      'rtf': 'application/rtf',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
    return MIMETypes[ext];
  }
  color = 'primary';
  mode = 'determinate';
  bufferValue = 0;
  readclinformations(id) {
    this.LoginService.returnClientsiD(id).subscribe(useri => {
      var response1 = JSON.parse(useri.toString());
      console.log(response1);
      this.form1.controls['Name'].setValue(response1[0].Name);
      this.form1.controls['OfficerName'].setValue(response1[0].OfficerName);
      this.form1.controls['CompanyName'].setValue(response1[0].CompanyName);
      this.form1.controls['Speciality'].setValue(response1[0].Speciality);
      this.form1.controls['Address'].setValue(response1[0].Address);
      this.form1.controls['Address2'].setValue(response1[0].Address2);
      this.form1.controls['Email'].setValue(response1[0].Email);
      this.form1.controls['Password'].setValue(response1[0].Password);
      this.form1.controls['Phone'].setValue(response1[0].Phone);
    });

  }
  user1: any;
  user2: any;
  user3: any;
  readClients() {
    this.LoginService.returnClients().subscribe(useri => {
      var response1 = JSON.parse(useri.toString());


      this.user1 = response1.filter(x => x.flag === 2);
      this.dataSource1.data = this.user1;
      this.user2 = response1.filter(x => x.flag === 1);
      this.dataSource11.data = this.user2;
      this.user3 = response1.filter(x => x.flag === 0);
      this.dataSource111.data = this.user3;
      console.log('response1', this.user1);
      console.log('response2', this.user2);
      console.log('response3', this.user3);
    });
  }
  applyFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource);
    //  console.log(this.dataSource.filteredData);
    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }
  }
  clientidselected: any;
  selectcl(id) {
    this.clientidselected = id;
    this.readdoc(id);
    this.readclinformations(id);
  }
  ////NEWS
  selectid: any = -1;
  selectid1: any = -1;
  today: any;
  sales: any;
  sales1: any;
  run(flag) {
    if (flag == 1) {
      this.LoginService.returnNews().subscribe(useri => {
        var response1 = JSON.parse(useri.toString());
        this.sales = response1;
        this.dataSource2.data = this.sales;
      });
    }
    else {
      this.LoginService.returnJournal().subscribe(useri => {
        var response1 = JSON.parse(useri.toString());
        this.sales1 = response1;
        this.dataSource3.data = this.sales1;
      });
    }
  }
  getline(id) {

    this.selectid = id;

    //alert(id)
  }
  deleteline(flag) {
    console.log('selectid', this.selectid)
    if (this.selectid != -1) {
      let a = {
        "ID": this.selectid,
        "image": '',
        "title": '',
        "url": '',

      }
      this.LoginService.deleteNews(this.selectid, flag).subscribe(users => {
        this.run(flag);
        this.selectid = -1;
      });
    }
    else
      alert('Please select Line to Delete');


  }
  newline(flag) {
    this.today = new Date();
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';

    let date = formatDate(this.today, format, locale);
    let a = {
      "ID": 0,
      "image": '',
      "title": '',
      "url": '',

    }
    this.LoginService.insertNews(a, flag).subscribe(users => {
      this.run(flag);
    });
  }
  updateinvoice(event, i, flag, flags) {
    let name = '';
    let age = 0;
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    console.log('event', event);
    console.log(this.sales[i]);
    if (flags == 1) {
      this.sales[i][flag] = event;
      let a = {
        "ID": this.sales[i]['ID'],
        "image": this.sales[i]['image'],
        "title": this.sales[i]['title'],
        "url": this.sales[i]['url'],
        "OrderNum": this.sales[i]['OrderNum'],
      }
     
      console.log('a', a);
      // this.wash.push(a);
      this.LoginService.updateNews(a, "1").subscribe(users => {
        // this.run();
      });
    }
    else {
      this.sales1[i][flag] = event;
      let a = {
        "ID": this.sales1[i]['ID'],
        "image": this.sales1[i]['image'],
        "title": this.sales1[i]['title'],
        "url": this.sales1[i]['url'],
        "OrderNum": this.sales[i]['OrderNum'],
      }

      console.log('a', a);
      // this.wash.push(a);
      this.LoginService.updateNews(a, "2").subscribe(users => {
        // this.run();
      });

    }
  }
  answer: any;
  fileProgress(fileInput: any, row) {
    const dialogRef = this.dialog.open(UploaddescriptionNComponent, {
      width: '350px',
      height: '350px',
      data: { answer: '', answer1: '' }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.answer = res;
      console.log(res);
      this.fileData = <File>fileInput.target.files[0];
      const formData = new FormData();
      formData.append('file', this.fileData);

      this.LoginService.CustomUploadFile(this.fileData.name, row.ID, this.answer, <File>fileInput.target.files[0]).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.value = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.value}%`);
            break;
          case HttpEventType.Response:
            console.log('User successfully created!', event.body);
            //this.getDocup();
            setTimeout(() => {
              this.uploadandfill = true;
              this.value = 0;
              alert('OK');
              this.selectcl(row.ID);
            }, 1500);
        }
      })
    });
  }
  accept(row) {
    console.log('Row', row.ID, 2);
    this.LoginService.activation(row.ID, 2).subscribe(useri => {
      alert(useri);
      this.readClients();
    });
  }
  reject(row) {
    const dialogRef = this.dialog.open(YesornoComponent, {
      width: '250px',
      height: '200px',
      data: { answer: 'Yes', answer1: 'No',answer2:'Are you sure you want to reject?' }
    });
    dialogRef.afterClosed().subscribe(res => {
      //alert(res);
      if (res == 'Yes') {
        this.LoginService.activation(row.ID, 3).subscribe(useri => {
          alert(useri);
          this.readClients();
        });
      }
    });
  }
  onSubmit2()
  {
    alert(this.clientidselected);
  }
}
