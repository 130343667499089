

<div mat-dialog-content>
  
   
   
<br><br>
 <p style="text-align: center;font-size: 15px;font-weight: 700;"> {{title1}}</p>
    <mat-accordion *ngFor="let item of text;">
        <!-- #docregion basic-panel -->
        <!-- #docregion hide-toggle -->
          <mat-expansion-panel hideToggle>       
            <mat-expansion-panel-header>
              <mat-panel-title>
             <b class="descU">  {{item.Title}}</b>
              </mat-panel-title>
              <mat-panel-description>              
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div [innerHTML]="item.Text"></div>
        
          </mat-expansion-panel>        
       
        </mat-accordion>  
   
</div>