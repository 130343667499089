<!-- Start Signup Area -->
<div class="main-banner">
    <br><br><br><br><br><br><br><br><br><br>

    <div class="test">
        <p class="whyus1">Please create an account to access our secure file transfer page
        </p>

        <div class="columns3">
            <img class="column3img1" src="assets/imgs/login1.PNG" alt="Mountains" >
        </div>
        <div class="columns4">
            <form fxLayout="column" [formGroup]="form" style=" padding : 30px;" (ngSubmit)="onSubmit()">
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Clinician's Name & Surname</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Name" required>
                        <mat-error></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Data Protection Officer's Name & Surname</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="OfficerName">
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Clinic / Company Name</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="CompanyName" required>
                        <mat-error></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Speciality</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Speciality" required>
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Address</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Address" required>
                        <mat-error></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Address Line 2</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Address2" >
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Email</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Email" required>
                        <mat-error>Email/Username!</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Phone</b></mat-label>
                        <input style="color: black;" matInput type="text"  formControlName="Phone" required>
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                   
                        <button type="button" class="registerbtn"  mat-raised-button (click)="open()">Site Privacy Policy and GDPR statement</button>&nbsp;
                        <mat-checkbox formControlName="test"   style="width: 50%; padding: 10px; color: black;" class="example-margin">I accept the Site Privacy Policy and GDPR statement</mat-checkbox>
                   
                </div>   
                <br>
                <mat-label class="registerbtn1"  (click)="onSubmit()"><b>Submit</b></mat-label>
            </form>


        </div>
        <div class="columns3">
            <img class="column3img2" src="assets/imgs/login2.png" alt="Mountains" >
        </div>

    </div>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br><br><br><br><br>
</div>
<!-- End Login Area -->