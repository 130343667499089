import { Component, OnInit } from '@angular/core';
import { UploaddescriptionComponent } from '../uploaddescription/uploaddescription.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  panelOpenState = false;
  ngOnInit() {
  }
  open1() {
    const dialogRef = this.dialog.open(UploaddescriptionComponent, {
      width: '85%',
      height: '80%',
      
      data: { answer: '1', answer1: '' }
    });
  }
  open2() {
    const dialogRef = this.dialog.open(UploaddescriptionComponent, {
      width: '85%',
      height: '80%',
      
      data: { answer: '2', answer1: '' }
    });
  }
  open3() {
    const dialogRef = this.dialog.open(UploaddescriptionComponent, {
      width: '85%',
      height: '80%',
      
      data: { answer: '3', answer1: '' }
    });
  }
  condition1: boolean = true;
  condition2: boolean = false;
  condition3: boolean = false;
  condition4: boolean = false;
  flag: any = "one";
  change(test) {
    this.flag = test;

    if (test == 'one') {
      // alert('1');
      this.condition1 = true;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = false;
    }
    if (test == 'two') {
      //alert('2');
      this.condition1 = false;
      this.condition2 = true;
      this.condition3 = false;
      this.condition4 = false;
    }
    if (test == 'three') {
      //alert('2');
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = true;
      this.condition4 = false;
    }
    if (test == 'four') {
      //alert('2');
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = true;
    }
  }
  back() {
    // alert(this.flag)
    if (this.flag == "one") {
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = true;
      this.flag = 'four';
      // alert('1='+this.flag)
    }
    else if (this.flag == "two") {
      this.condition1 = true;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = false;
      this.flag = 'one';
      // alert('2='+this.flag)
    }
    else if (this.flag == "three") {
      this.condition1 = false;
      this.condition2 = true;
      this.condition3 = false;
      this.condition4 = false;
      this.flag = 'two';
      //   alert('3='+this.flag)
    }
    else if (this.flag == "four") {
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = true;
      this.condition4 = false;
      this.flag = 'three';
      //  alert('4='+this.flag)
    }
  }
  next() {
    // alert(this.flag)
    if (this.flag == "one") {
      this.condition1 = false;
      this.condition2 = true;
      this.condition3 = false;
      this.condition4 = false;
      this.flag = 'two';
      // alert('1='+this.flag)
    }
    else if (this.flag == "two") {
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = true;
      this.condition4 = false;
      this.flag = 'three';
      // alert('2='+this.flag)
    }
    else if (this.flag == "three") {
      this.condition1 = false;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = true;
      this.flag = 'four';
      //   alert('3='+this.flag)
    }
    else if (this.flag == "four") {
      this.condition1 = true;
      this.condition2 = false;
      this.condition3 = false;
      this.condition4 = false;
      this.flag = 'one';
      //  alert('4='+this.flag)
    }
  }
  setStyles1() {
    if (this.flag == 'one') {
      let styles = {

        'font-weight': 'bold',  // normal
        'color': 'red'
      };
      return styles;
    }
    else
    {
      let styles = {

        'font-weight': 'normal',  // normal
        'color': 'white'
      };
      return styles;
    }
  }
  setStyles2() {
    if (this.flag == 'two') {
      let styles = {

        'font-weight': 'bold',  // normal
        'color': 'red'
      };
      return styles;
    }
    else
    {
      let styles = {

        'font-weight': 'normal',  // normal
        'color': 'white'
      };
      return styles;
    }
  }
  setStyles3() {
    if (this.flag == 'three') {
      let styles = {

        'font-weight': 'bold',  // normal
        'color': 'red'
      };
      return styles;
    }
    else
    {
      let styles = {

        'font-weight': 'normal',  // normal
        'color': 'white'
      };
      return styles;
    }
  }
  setStyles4() {
    if (this.flag == 'four') {
      let styles = {

        'font-weight': 'bold',  // normal
        'color': 'red'
      };
      return styles;
    }
    else
    {
      let styles = {

        'font-weight': 'normal',  // normal
        'color': 'white'
      };
      return styles;
    }

  }
}
