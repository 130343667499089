import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../../../login.service';
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  form: FormGroup;
  id: any = -1;
  value = 0;
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private LoginService: LoginService) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required],
    });
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id != -1) {
      //alert(this.id)
      this.LoginService.activation(this.id,1).subscribe(useri => {
       
        if (useri == 'Your account has been Verified')
        alert(useri)
        else
        alert(useri);
        //  this.router.navigate(['/']);
      });
    }
    else {
      // alert('opa')
    }
  }
  login() {
    let a = {
      "email": this.form.controls['Username'].value,
      "password": this.form.controls['Password'].value
    }
    this.LoginService.checkPass(a).subscribe(useri => {
      var response1 = JSON.parse(useri.toString());
      
      if (response1.length != 0) {
        if (response1[0].flag == 0) {
          alert('You must confirm your email')
        }
       else if (response1[0].flag == 1) {
          alert('You must wait for Activation!As soon as your account is activated you will receive another email with your login information')
        }
        else {
          this.LoginService.userlogin = response1;
          alert('Welcome Back '+response1[0].Name)
          if(response1[0].Name!="Admin")
          this.router.navigate(['features']);
          else
          this.router.navigate(['features-1']);
        }      
      }
      else
      alert('Error Username or Password')
    });
  }
  forgot()
  {
    if (this.form.controls['Username'].value!='')
    {
      this.LoginService.forgotpass(this.form.controls['Username'].value).subscribe(useri => {
        alert('Please check your email for the new Password')
      });
    }
  }

}
