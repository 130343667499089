<div mat-dialog-content>

    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;'>
        <strong><span style='font-size:27px;font-family:"Arial",sans-serif;color:black;'>ExMachina3d.com Privacy
                Policy</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;'><span
            style='font-size:15px;font-family:"Arial",sans-serif;'>Last updated February 01, 2022</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;'><span
            style="font-size:11px;">&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:5px;line-height:150%;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><span style='font-family:"Arial",sans-serif;color:black;'>INTRODUCTION</span></strong></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-family:"Arial",sans-serif;color:black;'>Thank you for choosing to be part of our community
            at&nbsp;</span><span style='font-family:"Arial",sans-serif;color:red;'>ExMachina3D.com&nbsp;</span><span
            style='font-family:"Arial",sans-serif;color:black;'>(&ldquo;company&rdquo;, &ldquo;we&rdquo;,
            &ldquo;us&rdquo;, or &ldquo;our&rdquo;). We are committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns about our policy, or our practices with regards to
            your personal information, please contact us at&nbsp;</span>info@exmachina3d.com<span
            style='font-family:"Arial",sans-serif;color:black;'>.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        <span style="font-size:7px;line-height:150%;color:black;">&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-family:"Arial",sans-serif;color:black;'>When you visit our website&nbsp;</span><a
            href="http://exmachina3d.com">http://exmachina3d.com</a> <span
            style='font-family:"Arial",sans-serif;color:black;'>(&ldquo;Site&rdquo;) and use our services, you trust us
            with your personal information. We take your privacy very seriously. In this privacy notice, we describe our
            privacy policy.</span><span style="color:black;">&nbsp;</span><span
            style='font-family:"Arial",sans-serif;color:black;'>We seek to explain to you in the clearest way possible
            what information we collect, how we use it and what rights you have in relation to it. We hope you take some
            time to read through it carefully, as it is important. If there are any terms in this privacy policy that
            you do not agree with, please discontinue use of our site and our services.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style="font-size:9px;line-height:150%;color:black;">&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-family:"Arial",sans-serif;color:black;'>This privacy policy applies to all information
            collected through our website&nbsp;</span><a href="http://exmachina3d.com">http://exmachina3d.com</a> <span
            style='font-family:"Arial",sans-serif;color:black;'>&nbsp;(we refer to it in this privacy policy as the
            &quot;<strong>Site</strong>&quot;). &nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><span style='font-family:"Arial",sans-serif;color:black;'>Please read this privacy policy carefully as
                it will help you make informed decisions about sharing your personal information with us.
                &nbsp;</span></strong></p>
    <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:16px;font-family:"Times New Roman",serif;margin-top:0cm;margin-bottom:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="line-height:150%;color:black;">WHAT INFORMATION DO WE COLLECT?&nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h2
        style='margin-top:12.0pt;margin-right:0cm;margin-bottom:3.0pt;margin-left:0cm;font-size:19px;font-family:"Cambria",serif;font-weight:bold;font-style:italic;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:16px;line-height:150%;font-family:"Arial",sans-serif;color:black;'>Personal information
            you disclose to us&nbsp;</span><span
            style='font-size:16px;line-height:150%;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></h2>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span
                    style='line-height:150%;font-family:"Arial",sans-serif;color:#434343;'>&nbsp;</span></em></strong>
    </p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;<span style="color:black;">We collect
                    personal information that you provide to us such as name and contact information.</span></span></em>
    </p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:12.0pt;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='line-height:150%;font-family:"Arial",sans-serif;color:black;'>We collect personal information that
            you voluntarily provide to us when expressing an interest in obtaining information about us or our services
            or otherwise contacting us.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:12.0pt;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='line-height:150%;font-family:"Arial",sans-serif;'>The personal information that we collect depends
            on the context of your interactions with us and the Site, the choices you make and the features you use. The
            personal information we collect can include the following:&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:12.0pt;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:12.0pt;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>Name and Contact
                Data.</span></strong><span
            style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We collect your first and last
            name, email address and phone number.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:12.0pt;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>All personal information that you
            provide to us must be true, complete and accurate, and you must notify us of any changes to such personal
            information. &nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h2
        style='margin-top:12.0pt;margin-right:0cm;margin-bottom:3.0pt;margin-left:0cm;font-size:19px;font-family:"Cambria",serif;font-weight:bold;font-style:italic;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;color:black;'>Information
            automatically collected</span></h2>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;Some information &ndash;
                such as IP address and/or browser and device characteristics &ndash; is collected automatically when you
                visit our website.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We automatically collect certain
            information when you visit, use or navigate the Site. This information does not reveal your specific
            identity (like your name or contact information) but may include device and usage information, such as your
            IP address, browser and device characteristics, operating system, language preferences, referring URLs,
            device name, country, location, information about how and when you use our Site and other technical
            information. &nbsp;This information is primarily needed to maintain the security and operation of our Site,
            and for our internal analytics and reporting purposes.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>Like many businesses, we also
            collect information through cookies and similar technologies.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h2
        style='margin-top:12.0pt;margin-right:0cm;margin-bottom:3.0pt;margin-left:0cm;font-size:19px;font-family:"Cambria",serif;font-weight:bold;font-style:italic;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;color:black;'>Information collected
            from other Sources</span></h2>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We may collect limited
                data from public databases, marketing partners, social media platforms, and other outside
                sources.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We may obtain information about
            you from other sources, such as public databases, joint marketing partners, social media platforms (such as
            Facebook), as well as from other third parties. Examples of the information we receive from other sources
            include: social media profile information (your name, gender, birthday, email, current city, state and
            country, user identification numbers for your contacts, profile picture URL and any other information that
            you choose to make public); marketing leads and search results and links.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">HOW DO WE USE YOUR INFORMATION?&nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We process your
                information for purposes based on legitimate business interests, the fulfillment of our contract with
                you, compliance with our legal obligations, and/or your consent.</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We use personal information
            collected via our Site for a variety of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate business interests (&quot;Business
            Purposes&quot;), in order to enter into or perform a contract with you (&quot;Contractual&quot;), with your
            consent (&quot;Consent&quot;), and/or for compliance with our legal obligations (&quot;Legal Reasons&quot;).
            We indicate the specific processing grounds we rely on next to each purpose listed below. &nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We use the information we collect
            or receive: &nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>To send administrative
                        information to you</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>&nbsp;for Business Purposes,
                    Legal Reasons and/or possibly Contractual. We may use your personal information to send you service
                    and new feature information and/or information about changes to our terms, conditions, and
                    policies.</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>Request
                        Feedback&nbsp;</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>for our Business Purposes
                    and/or with your Consent. We may use your information to request feedback and to contact you about
                    your use of our Site.&nbsp;</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>To protect our
                        Site&nbsp;</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>for Business Purposes and/or
                    Legal Reasons. &nbsp;We may use your information as part of our efforts to keep our Site safe and
                    secure (for example, for fraud monitoring and prevention).</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>To enforce our terms,
                        conditions and policies</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>&nbsp;for Business Purposes,
                    Legal Reasons and/or possibly Contractual.</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>To respond to legal
                        requests and prevent harm&nbsp;</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>for Legal Reasons. If we
                    receive a subpoena or other legal request, we may need to inspect the data we hold to determine how
                    to respond.</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:8px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>For other Business
                        Purposes</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>. We may use your
                    information for other Business Purposes, such as data analysis, identifying usage trends,
                    determining the effectiveness of our promotional campaigns and to evaluate and improve our Site,
                    services, marketing and your experience.</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-bottom:12.0pt;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">WILL YOUR INFORMATION BE SHARED WITH
            ANYONE?&nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We only share information
                with your consent, to comply with laws, to protect your rights, or to fulfill business
                obligations.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We only share and disclose your
            information in the following situations:</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
                <h2
                    style='margin-top:12.0pt;margin-right:0cm;margin-bottom:3.0pt;margin-left:0cm;font-size:19px;font-family:"Cambria",serif;font-weight:bold;font-style:italic;'>
                    <span style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>Compliance with
                        Laws</span><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>.
                        We may disclose your information where we are legally required to do so in order to comply with
                        applicable law, governmental requests, a judicial proceeding, court order, or legal process,
                        such as in response to a court order or a subpoena (including in response to public authorities
                        to meet national security or law enforcement requirements).</span></h2>
            </li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>Vital Interests and
                        Legal Rights</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>. We may disclose your
                    information where we believe it is necessary to investigate, prevent, or take action regarding
                    potential violations of our policies, suspected fraud, situations involving potential threats to the
                    safety of any person and illegal activities, or as evidence in litigation in which we are
                    involved.&nbsp;</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>Vendors, Consultants and
                        Other Third-Party Service Providers</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>. We may share your data
                    with third party vendors, service providers, contractors or agents who perform services for us or on
                    our behalf and require access to such information to do that work. Examples include: payment
                    processing, data analysis, email delivery, hosting services, customer service and marketing efforts.
                    We may allow selected third parties to use tracking technology on the Site, which will enable them
                    to collect data about how you interact with the Site over time. &nbsp;This information may be used
                    to, among other things, analyze and track data, determine the popularity of certain content and
                    better understand online activity. Unless described in this Policy, we do not share, sell, rent or
                    trade any of your information with third parties for their promotional purposes.&nbsp;</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>Business
                        Transfers</span></strong><span
                    style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>. We may share or transfer
                    your information in connection with, or during negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our business to another company.</span></li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
                <h2
                    style='margin-top:12.0pt;margin-right:0cm;margin-bottom:3.0pt;margin-left:0cm;font-size:19px;font-family:"Cambria",serif;font-weight:bold;font-style:italic;'>
                    <span style='line-height:150%;font-family:"Arial",sans-serif;font-size:15px;'>With your
                        Consent.&nbsp;</span><span
                        style='line-height:150%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>We
                        may disclose your personal information for any other purpose with your consent.</span></h2>
            </li>
        </ul>
    </div>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">DO WE USE COOKIES AND OTHER TRACKING
            TECHNOLOGIES?</span><span style="font-size:19px;line-height:150%;color:#595959;">&nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We may use cookies and
                other tracking technologies to collect and store your information.</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;text-align:justify;line-height:150%;'>
        <span style="font-size:15px;line-height:150%;font-weight:normal;">We may use cookies and similar tracking
            technologies (like web beacons and pixels) to access or store information.&nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            &nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:&nbsp;</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We may transfer, store, and
                process your information in countries other than your own.</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>Our server is located in Data
            Center in Germany. If you are accessing our Site from outside the Rebublic of Cyprus, please be aware that
            your information may be transferred to, stored, and processed by us in our facilities and by those third
            parties with whom we may share your personal information (see &quot;Disclosure of Your Information&quot;
            above), in other countries .&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>If you are a resident in the
            European Economic Area, then these countries may not have data protection or other laws as comprehensive as
            those in your country. We will however take all necessary measures to protect your personal information in
            accordance with this privacy policy and applicable law.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>European Commission&apos;s
            Standard Contractual Clauses: Such measures implementing the European Commission&apos;s Standard Contractual
            Clauses for transfers of personal information between our group companies and between us and our third-party
            providers, which require all such recipients to protect personal information that they process from the EEA
            in accordance with European data protection laws.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span>
    </h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We are not responsible for
                the safety of any information that you share with third-party providers who advertise, but are not
                affiliated with, our website.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>The Site may contain
            advertisements from third parties that are not affiliated with us and which may link to other websites or
            online services. We cannot guarantee the safety and privacy of data you provide to any third parties. Any
            data collected by third parties is not covered by this privacy policy. We are not responsible for the
            content or privacy and security practices and policies of any third parties, including other websites,
            services or applications that may be linked to or from the Site. You should review the policies of such
            third parties and contact them directly to respond to your questions.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;</span>
    </h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We keep your information
                for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise
                required by law.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We will only keep your personal
            information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer
            retention period is required or permitted by law (such as tax, accounting or other legal
            requirements).&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>When we have no ongoing legitimate
            business need to process your personal information, we will either delete or anonymize it, or, if this is
            not possible (for example, because your personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from any further processing until deletion is
            possible.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;</span>
    </h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We aim to protect your
                personal information through a system of organizational and technical security
                measures.&nbsp;</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We have implemented appropriate
            technical and organizational security measures designed to protect the security of any personal information
            we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure.
            Although we will do our best to protect your personal information, transmission of personal information to
            and from our Site is at your own risk. You should only access the services within a secure
            environment.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">DO WE COLLECT INFORMATION FROM MINORS?&nbsp;</span>
    </h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;We do not knowingly
                collect data from or market to children under 18 years of age.</span></em><span
            style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We do not knowingly solicit data
            from or market to children under 18 years of age. &nbsp;By using the Site, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s
            use of the Site. &nbsp;If we learn that personal information from users less than 18 years of age has been
            collected, we will take reasonable measures to promptly delete such data from our records. &nbsp;If you
            become aware of any data we have collected from children under age 18, please contact us
            at&nbsp;</span>info@exmachina3d.com<span
            style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">WHAT ARE YOUR PRIVACY RIGHTS?</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;In some regions, such as
                the European Economic Area, you have rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your account at any time.&nbsp;</span></em>
    </p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;text-align:justify;line-height:150%;'>
        <span style="font-size:15px;line-height:150%;font-weight:normal;">&nbsp;</span></h1>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;text-align:justify;line-height:150%;'>
        <span style="font-size:15px;line-height:150%;font-weight:normal;">In some regions (like the European Economic
            Area), you have certain rights under applicable data protection laws. &nbsp;These may include the right (i)
            to request access and obtain a copy of your personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability.
            In certain circumstances, you may also have the right to object to the processing of your personal
            information. To make such a request, please use the contact details provided below. We will consider and act
            upon any request in accordance with applicable data protection laws. &nbsp;</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;text-align:justify;line-height:150%;'>
        <span style="font-size:15px;line-height:150%;font-weight:normal;">If we are relying on your consent to process
            your personal information, you have the right to withdraw your consent at any time. Please note however that
            this will not affect the lawfulness of the processing before its withdrawal.</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>If you are resident in the
            European Economic Area and you believe we are unlawfully processing your personal information, you also have
            the right to complain to your local data protection supervisory authority. You can find their contact
            details here:&nbsp;</span><a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"><span
                style="font-size:15px;line-height:150%;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a><span
            style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>.</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <strong><u><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>Cookies and similar
                    technologies</span></u></strong><span
            style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and
            to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or
            services of our Site.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">DO WE MAKE UPDATES TO THIS POLICY?</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <strong><em><span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>In
                    Short:</span></em></strong><em><span
                style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>&nbsp;Yes, we will update this
                policy as necessary to stay compliant with relevant laws.</span></em></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>We may update this privacy policy
            from time to time. The updated version will be indicated by an updated &ldquo;Revised&rdquo; date and the
            updated version will be effective as soon as it is accessible. We encourage you to review this privacy
            policy frequently to be informed of how we are protecting your information.&nbsp;</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <h1
        style='margin-top:0cm;margin-right:0cm;margin-bottom:2.0pt;margin-left:0cm;font-size:21px;font-family:"Arial",sans-serif;font-weight:bold;line-height:150%;'>
        <span style="font-size:19px;line-height:150%;color:black;">HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></h1>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;margin-top:0cm;margin-bottom:6.0pt;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>If you have questions or comments
            about this policy, email us at&nbsp;</span><a href="mailto:info@exmachina3d.com">info@exmachina3d.com</a>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;'>or by post to:</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;line-height:150%;'>
        <span style='font-size:15px;line-height:150%;font-family:"Arial",sans-serif;color:red;'>Andreas Artopoulos, 22
            Griva Digeni St, Flat 301, Limassol 3106, Cyprus</span></p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
    <p
        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;line-height:150%;'>
        &nbsp;</p>
</div>