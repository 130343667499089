<div class="main-banner">



    <div class="test122">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <p class="whyus2">3D Planning
        </p>
        <p class="whyus3">Virtual surgical planning allows an accurate simulation of the planned procedure before the
            patient enters the operating room </p>

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <div>
            <div class="elementtS22">
                <img class="atS32" src="assets/imgs/planing1.gif" style="height: 467px;     width: 895px;" />
                <div class="atS42">
                   
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black; font-weight: 700; font-size: 21px;">Facial cosmetic surgery

                    </p>

                    <p class="ttS12">Various cosmetic procedures such as rhinoplasty and chin augmentation can be
                        instantaneously simulated with a simple to use slider-based adjustment. Help your patients
                        decide on the best treatment plan for them by demonstrating likely outcomes of single or
                        multiple procedures in the three dimensions.


                    </p>

                </div>


            </div>

        </div>

        <div class="spacet1">
            <br> <br> <br> <br>
        </div>
        <div>
            <div class="elementtS12">
                <img class="atS12" src="assets/imgs/planing2.gif" style="height: 492px;width: 895px;     margin-top: -8px;
                margin-left: -9px;" />

                <div class="atS22">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Implantology
                    </p>
                    <p class="ttS12">
                        Dental implant placement and prosthetic rehabilitation are more predictable with virtual
                        surgical planning. Being able to view in the 3 dimensions all the hard and soft tissues,
                        anatomical structures such as nerves and the sinus floor as well as the planned final prosthetic
                        restoration facilitates selection of the optimum implant positions and best possible prosthetic
                        outcome. Demonstrating options to your patients will also help them understand the procedures
                        and consent to the treatment plan.




                    </p>

                </div>
            </div>


        </div>


        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS42">
                <img class="atS72" src="assets/imgs/planing3.gif" style="height: 483px;width: 895px;    margin-top: -9px;
                margin-right: -6px;" />
                <div class="atS82">
                   
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Orthognathic surgery

                    </p>
                    <p class="ttS12">Complex surgical procedures to correct facial asymmetry and jaw discrepancies can
                        be very challenging to plan correctly and safely based on plain radiographs! Virtual surgical
                        planning allows us to visualise the whole anatomy of the skull and accurate scans of the
                        dentition and simulate each procedure in a realistic manner. The 3D hard tissue data can also be
                        merged with a 3D facial scan to enable soft tissue outcome prediction.



                    </p>
                </div>


            </div>

        </div>

        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS32">
                <img class="atS52" src="assets/imgs/test2.gif" style="height: 483px;width: 895px;    margin-top: -9px;
                margin-right: -6px;" />
               
               

                <div class="atS62">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Breast procedures

                    </p>
                    <p class="ttS12">
                        Using the same state of the art technology as for facial surgery, we can plan and simulate
                        various breast procedures, such as mastopexy, augmentation or reconstruction following
                        mastectomy. Our planning software provides an accurate visualization of the anticipated outcome
                        but also accurate quantitative data (such as linear and volumetric measurements) to facilitate
                        both planning and execution of the procedures.


                    </p>
                </div>
            </div>
            <br><br><br>

        </div>



    </div>
</div>