<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="divider"></div>

    <div class="container" style="max-width: 100%;padding-left: 0px;padding-right: 0px;">

        <div class="rows" >
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget" style="width: 280px;">

                    <p style="color: black;font-size: 17px;font-weight: 400;"><b>Let's Connect</b> </p>
                    <p style="color: black; font-size: 17px;line-height: 1.1em;">Contact us in your preferred method and
                        tell us about your next case </p>

                        <p (click)="gotoTop()" style="cursor: pointer;margin-top: 185px;color: black;font-size: 17px;font-weight: 400;"><b>BACK TO TOP</b> </p>
                        
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div>
                        <h3 style="color: black;margin-bottom: 0px;"><b>Address</b></h3>
                        <p style="color: black;margin-bottom: 0px;">
                            <a style="color: black;"
                                href="https://www.google.com/maps/place/%CE%93%CE%B5%CF%8E%CF%81%CE%B3%CE%B9%CE%BF%CF%85+%CE%93%CF%81%CE%AF%CE%B2%CE%B1+%CE%94%CE%B9%CE%B3%CE%B5%CE%BD%CE%AE+22-3rd+Floor,+%CE%9B%CE%B5%CE%BC%CE%B5%CF%83%CF%8C%CF%82,+%CE%9A%CF%8D%CF%80%CF%81%CE%BF%CF%82/@34.6881576,33.0562295,20.33z/data=!4m5!3m4!1s0x14e7336a9f24b325:0xce4503f71523655c!8m2!3d34.6882348!4d33.0562828"
                                target="_blank">
                                22, Griva Digeni St</a>
                        </p>
                        <p style="color: black;margin-bottom: 0px;">
                            <a style="color: black;"
                                href="https://www.google.com/maps/place/%CE%93%CE%B5%CF%8E%CF%81%CE%B3%CE%B9%CE%BF%CF%85+%CE%93%CF%81%CE%AF%CE%B2%CE%B1+%CE%94%CE%B9%CE%B3%CE%B5%CE%BD%CE%AE+22-3rd+Floor,+%CE%9B%CE%B5%CE%BC%CE%B5%CF%83%CF%8C%CF%82,+%CE%9A%CF%8D%CF%80%CF%81%CE%BF%CF%82/@34.6881576,33.0562295,20.33z/data=!4m5!3m4!1s0x14e7336a9f24b325:0xce4503f71523655c!8m2!3d34.6882348!4d33.0562828"
                                target="_blank">
                                3rd Floor</a>
                        </p>
                        <p style="color: black;margin-bottom: 0px;">
                            <a style="color: black;"
                                href="https://www.google.com/maps/place/%CE%93%CE%B5%CF%8E%CF%81%CE%B3%CE%B9%CE%BF%CF%85+%CE%93%CF%81%CE%AF%CE%B2%CE%B1+%CE%94%CE%B9%CE%B3%CE%B5%CE%BD%CE%AE+22-3rd+Floor,+%CE%9B%CE%B5%CE%BC%CE%B5%CF%83%CF%8C%CF%82,+%CE%9A%CF%8D%CF%80%CF%81%CE%BF%CF%82/@34.6881576,33.0562295,20.33z/data=!4m5!3m4!1s0x14e7336a9f24b325:0xce4503f71523655c!8m2!3d34.6882348!4d33.0562828"
                                target="_blank">
                                Limassol 3106</a>
                        </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="color: black;margin-bottom: 0px;"><b>Email</b></h3>
                        <p style="color: black;margin-bottom: 0px;">
                            <a style="color: black;" href="mailto:info@exmachina3d.com">info@exmachina3d.com</a>
                        </p>

                    </div>
                    <br>
                    <div>
                        <h3 style="color: black;margin-bottom: 0px;"><b>Phone</b></h3>
                        <p style="color: black;margin-bottom: 0px;">
                            <a style="color: black;" href="tel:+35796805545">+357 96 805 545</a>
                        </p>

                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                      

                    </ul>
                    <ul>
                      
                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-6" style="padding-right: 0px;padding-left: 0px;">
                <div class="column">
                    <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form"
                        (ngSubmit)="onSubmit()">

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                style="display: contents !important;">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" type="text" required style="font-weight: bold;">
                                <mat-icon style="color: black;" matSuffix class="secondary-text">account_circle</mat-icon>
                                <mat-error>Name!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                style="display: contents !important;">
                                <mat-label>Last Name</mat-label>
                                <input  matInput formControlName="surname" type="text" required
                                    style="font-weight: bold;">
                                <mat-icon style="color: black;" matSuffix class="secondary-text">phone</mat-icon>
                                <mat-error>Last Name!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                style="display: contents !important;">
                                <mat-label>Email Address</mat-label>
                                <input matInput formControlName="email" type="email" required
                                    style="font-weight: bold;">
                                <mat-icon style="color: black;" matSuffix class="secondary-text">email</mat-icon>
                                <mat-error>Email Address!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                style="display: contents !important;">
                                <mat-label>Subject</mat-label>
                                <textarea rows="10" matInput formControlName="sub"
                                    style="font-weight: bold;"></textarea>

                                <mat-icon style="color: black;" matSuffix class="secondary-text">turned_in</mat-icon>
                                <mat-error>Subject</mat-error>
                            </mat-form-field>
                        </div>


                        <button class="registerbtn" type="submit" mat-raised-button>Submit</button>&nbsp;

                    </form>
                </div>
            </div>
        </div>

        <div class="copyright-area" style="background-color: black; width: 100%;">
            <p>&copy; Copyright {{currentYear}} ExMachina 3D Medical Modeling Ltd. Created by <a href="citrusit.eu"
                    target="_blank">Citrus IT Pro Services</a> </p>
        </div>
    </div>
    
   
</footer>
<!-- End Footer Area -->
