<div class="main-banner">



    <div class="test122">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <p class="whyus2">3D Analysis
        </p>
        <p class="whyus3">We use similar tools as in industrial inspection of parts and quality control to align,
            register and compare 3D data and produce quantitative and qualitative reports for your clinical needs. </p>

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <div>
            <div class="elementtS23">
                <img class="atS33" src="assets/imgs/analysis1.webp" style="height: 467px;     width: 895px;" />
                <div class="atS43">
                    
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black; font-weight: 700; font-size: 21px;">Facial asymmetry</p>
                    <p class="ttS13">Correction of facial asymmetry is the objective of various procedures in plastic,
                        orthognathic and reconstructive surgery. Accurate quantification of the asymmetry in the 3
                        dimensions is a prerequisite for treatment planning and successful outcomes. 3D Analysis
                        produces an easy to understand visualization of asymmetry through color-coded deviation maps
                        between left and right side, accurate measurements of volumetric differences as well as linear
                        measurements between anatomical landmarks.
                    </p>
                </div>
            </div>
        </div>

        <div class="spacet1">
            <br> <br> <br> <br>
        </div>
        <div>
            <div class="elementtS13">
                <img class="atS13" src="assets/imgs/analysis2.webp" style="height: 467px;width:895px;" />

                <div class="atS23">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Soft tissue changes

                    </p>
                    <p class="ttS13">
                        Sometimes the key to achieving the best possible outcome is to intervene at the right moment.
                        This may not be easy to determine, as for example in younger patients still in development or
                        when soft tissue healing may be prolonged due to extensive surgery or irradiation of the
                        tissues. 3D Analysis allows quantification of soft tissue changes at sub-millimetre scale,
                        giving you and your patients the confidence to make an informed decision.
                    </p>
                </div>
            </div>
        </div>


        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS43">
                <img class="atS73" src="assets/imgs/analysis3.gif" style="height: 486px;width: 895px;" />
                <div class="atS83">
                  
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Skin analysis
                    </p>
                    <p class="ttS13">Subsurface skin conditions can easily be visualised with one click, using Canfield
                        Scientific Imaging proprietary RBX® technology, which separates the unique colour signatures of
                        red and brown skin components. Communicating to your patients their needs has never been
                        simpler!
                    </p>
                </div>
            </div>
        </div>

        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS33">
                <img class="atS13" src="assets/imgs/analysis4.webp" style="height: 471px;width: 895px;" />


                <div class="atS63">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Pre / Post operative comparison
                    </p>
                    <p class="ttS13">
                        Evaluating surgical outcomes objectively is not an easy task as soft tissue changes in the 3
                        dimensions are impossible to measure with conventional techniques. 3D Analysis allows accurate
                        comparisons between series of 3D images to be done in seconds, providing you with detailed
                        qualitative and quantitative data to demonstrate to your patients what you have achieved and
                        help them appreciate the treatment progress.
                    </p>
                </div>
            </div>
            <br><br><br>

        </div>



    </div>
</div>