import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import{UploaddescriptionN1Component}from '../../pages/uploaddescriptionN1/uploaddescriptionN1.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor(public dialog: MatDialog,) { }
flag:any=1;
    ngOnInit() {
    }
open()
{
    console.log(this.flag)
    if(this.flag==1){
        this.flag=0;
            const dialogRef = this.dialog.open(UploaddescriptionN1Component, {
        width: '85%',
        height: '80%',
        
        data: { answer: '', answer1: '' }
        
      });
      dialogRef.afterClosed().subscribe(res => {
        this.flag=1;
      });
    }
}
}
