<div class="main-banner">
    <br><br><br><br><br><br>

    <div class="test" style="height: 500px;width: 100%;">
        <p class="whyus1">Please enter case information to upload file
        </p>
      
        <button style="margin-left: 25%;" color="primary" (click)="exit()">Log Out
            <mat-icon>exit_to_app</mat-icon>
        </button>
        <div class="columns3">
            <img class="column5img1" src="assets/imgs/login1.PNG" alt="Mountains">
        </div>
        <div class="columns4">

            <form fxLayout="column" [formGroup]="form" style=" padding : 30px;" (ngSubmit)="onSubmit()">
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Case Identifier</b></mat-label>
                        <input style="color: black;" matInput type="text" formControlName="CaseIdentifier" required>
                        <mat-error></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Data Protection Officer's Approval</b></mat-label>
                        <input style="color: black;" matInput type="text" formControlName="DataProtection" required>
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                        <mat-label style="color: black;"><b>Type of case</b></mat-label>
                        <input style="color: black;" matInput type="text" formControlName="Typeofcase" required>
                        <mat-error></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                        <mat-label style="color: black;"><b>Surgery Date</b></mat-label>
                        <input matInput formControlName="Date" required type="date"
                            style="font-weight: bold;color: black;">
                        <mat-error></mat-error>
                    </mat-form-field>
                </div>

                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                    <input style="color: black;" color="primary" mat-fab type="file" name="image"
                        (change)="fileProgress($event)" multiple />
                </div>
                <br>
                <mat-label *ngIf="uploadandfill" class="registerbtn1" (click)="onSubmit()"><b>Submit</b></mat-label>
            </form>
            <br>
            <mat-tab-group  dynamicHeight>
                <mat-tab *ngFor="let person of CaseIdentifier;let i = index"  [label]="person.Name">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="true">
                            <!-- #enddocregion hide-toggle -->
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Existing Documents
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div style="width: 100%;   overflow: auto;">
                                <table mat-table [dataSource]="person.datasource " matSort class="mat-elevation-z8">

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="Docname">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Docname </th>
                                        <td mat-cell *matCellDef="let element"> {{element.Docname}} </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="CaseIdentifier">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CaseIdentifier </th>
                                        <td mat-cell *matCellDef="let element"> {{element.CaseIdentifier}} </td>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="DataProtection">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DataProtection </th>
                                        <td mat-cell *matCellDef="let element"> {{element.DataProtection}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Typeofcase">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Typeofcase </th>
                                        <td mat-cell *matCellDef="let element"> {{element.Typeofcase}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.Date}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Downloads">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Downloads </th>
                                        <td mat-cell *matCellDef="let element"> {{element.Downloads}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="See">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Read</th>
                                        <td mat-cell *matCellDef="let element"> {{element.See}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTION </th>
                                        <mat-cell *matCellDef="let row">

                                            <button mat-mini-fab color="primary" (click)="dowload(row)">
                                                <mat-icon>cloud_download</mat-icon>
                                            </button>
                                            <button mat-mini-fab color="primary" (click)="delete(row)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </mat-cell>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-tab>

            </mat-tab-group>
            <br>
            <mat-accordion>
                <!-- #docregion basic-panel -->
                <!-- #docregion hide-toggle -->
                <mat-expansion-panel hideToggle>
                    <!-- #enddocregion hide-toggle -->
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            PERSONAL INFORMATIONS
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form fxLayout="column" [formGroup]="form1" style=" padding : 30px;" (ngSubmit)="onSubmit()">
                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutGap="20px">
                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                <mat-label style="color: black;"><b>Clinician's Name & Surname</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Name" required>
                                <mat-error></mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                <mat-label style="color: black;"><b>Data Protection Officer's Name & Surname</b>
                                </mat-label>
                                <input style="color: black;" matInput type="text" formControlName="OfficerName">
                                <mat-error></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutGap="20px">
                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                <mat-label style="color: black;"><b>Clinic / Company Name</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="CompanyName"
                                    required>
                                <mat-error></mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                <mat-label style="color: black;"><b>Speciality</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Speciality" required>
                                <mat-error></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutGap="20px">
                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                <mat-label style="color: black;"><b>Address</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Address" required>
                                <mat-error></mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                <mat-label style="color: black;"><b>Address Line 2</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Address2" required>
                                <mat-error></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutGap="20px">
                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                <mat-label style="color: black;"><b>Email</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Email" required>
                                <mat-error>Email/Username!</mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                <mat-label style="color: black;"><b>Phone</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Phone" required>
                                <mat-error></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutGap="20px">
                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                <mat-label style="color: black;"><b>Password</b></mat-label>
                                <input style="color: black;" matInput type="text" formControlName="Password" required>
                                <mat-error></mat-error>
                            </mat-form-field>

                        </div>
                        <br>
                        <mat-label class="registerbtn2" (click)="onSubmit1()"><b>Sumbit</b></mat-label>
                        <mat-label class="registerbtn3" (click)="onSubmit2()"><b>Delete</b></mat-label>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="columns3">
            <img class="column5img2" src="assets/imgs/login2.png" alt="Mountains">
        </div>

    </div>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br><br><br><br><br>
</div>