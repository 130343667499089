<div class="main-banner">



    <div class="test12">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <br><br> <br><br>
        <p class="whyus2">Services</p>
        <p class="whyus3">Find out more about the ways we can enhance your clinical practice
        </p>
       

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <div>
            <div class="elementtS2">
                <img class="atS3" src="assets/imgs/services1.webp" />
                <div class="atS4">
                  
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black; font-weight: 700; font-size: 21px;">3D Imaging </p>
                    
                    <p class="ttS1">We offer a state of the art 3D imaging service for the face, breast and
                         body, using non-invasive, optical imaging technology (digital stereophotogrammetry).
                    </p>
                    <p > <a style="color: black;font-weight: 500;font-size: 17px;" routerLink="/imaging" routerLinkActive="active">  Click here to find out more </a></p>
                </div>


            </div>

        </div>

        <div class="spacet1">
            <br> <br> <br> <br> 
        </div>
        <div>
            <div class="elementtS1">
                <img class="atS1" src="assets/imgs/services2.webp" />

                <div class="atS2">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">3D Planning     </p>                    
                    <p class="ttS1">
                        We use a suite of specialised software to simulate your planned surgical procedure. 
                        We have experience in a wide range of surgical specialties, from maxillofacial to plastic
                         surgery and from oncology to prosthodontics.


                    </p>
                    <p > <a style="color: black;font-weight: 500;font-size: 17px;" routerLink="/planing" routerLinkActive="active">  Click here to find out more </a></p>
                </div>
            </div>

            

        </div>

      
        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS4">
                <img class="atS7" src="assets/imgs/services3.webp" />
                <div class="atS8">
                    
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">3D Analysis                </p> 
                    <p class="ttS1">We employ custom tools to generate 3D analyses accompanied by detailed qualitative and 
                        quantitative reports for diagnosis, monitoring, treatment planning, evaluation of treatment outcomes and clinical research

                    </p>
                    <p > <a style="color: black;font-weight: 500;font-size: 17px;" routerLink="/analysis" routerLinkActive="active">  Click here to find out more </a></p>
                </div>


            </div>

        </div>

        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS3">
                <img class="atS5" src="assets/imgs/services4.webp" />

                <div class="atS6">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">3D Printing</p> 
                    <p class="ttS1">
                        We provide stereolithographic anatomical models in real size, biocompatible guides for 
                        surgical navigation and other custom appliances for a wide range of clinical applications
                    </p>
                    <p > <a style="color: black;font-weight: 500;font-size: 17px;" routerLink="/printing" routerLinkActive="active">  Click here to find out more </a></p>
                </div>
            </div>
            <br><br><br>

        </div>

        

    </div>
</div>