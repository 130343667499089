<div class="main-banner">
  <div class="test12" style="height: 1000px;">
    <video style="    object-fit: fill;
    height: 750px;
    width: 100%;" loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/imgs/test1.mp4" type="video/mp4">
    </video>


    <div class="test111">
      <div class="float-container">

        <div class="float-child">
          <div class="green"> <img src="assets/imgs/Guided Implantology.png" (click)="open1()"
              style="cursor: pointer;" /></div>
        </div>

        <div class="float-child">
          <div class="blue"> <img src="assets/imgs/Facial Imaging.png" style="cursor: pointer;" (click)="open2()"/></div>
        </div>

      </div>
      <div class="float-container">

        <div class="float-child">
          <div class="green"> <img src="assets/imgs/Maxillofacial & Orthognathic.png" style="cursor: pointer;" (click)="open3()"/></div>
        </div>

        <div class="float-child">
          <div class="blue"> <img src="assets/imgs/Breast & Body Contouring.png" style="cursor: pointer;" /></div>
        </div>

      </div>


      <!--   <div style="width: 100%; display: table;">
        <div style="display: table-row; height: 100px;">
            <div style="width: 50%; display: table-cell;">
                <img src="assets/imgs/Guided Implantology.png" />
            </div>
            <div style="display: table-cell;"> 
                <img src="assets/imgs/Facial Imaging.png" />
            </div>
        </div>
    </div>
     <div style="width: 100%; display: table;">
        <div style="display: table-row; height: 100px;">
            <div style="width: 50%; display: table-cell; ">
                <img src="assets/imgs/Maxillofacial & Orthognathic.png" />
            </div>
            <div style="display: table-cell;"> 
                <img src="assets/imgs/Breast & Body Contouring.png" />
            </div>
        </div>
    </div>-->


    </div>
  </div>
</div>