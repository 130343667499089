import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {LoginService} from '../../../login.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    currentYear: any;
    form: FormGroup;
    constructor( private _formBuilder: FormBuilder,private LoginService: LoginService) { }

    ngOnInit() {
      this.form = this._formBuilder.group({
        name: ['', Validators.required],
        surname: ['', Validators.required],
        sub: ['', Validators.required],
        email: ['', [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],

    });
        this.currentYear = new Date().getFullYear();
    }
    onSubmit() {
      if (this.form.valid) {
       //   if ((this.name != '') && (this.surname != '') && (this.sub != '')) {
            //  console.log('name', this.name);
            //  console.log('surname', this.surname);
            //  console.log('sub', this.sub);
              let a = {
                  "subject": 'Email From Web site: Send Email from ' +this.form.controls['name'].value + ' ' + this.form.controls['surname'].value,
                  "email": this.form.controls['sub'].value,
                  "emailaddress": this.form.controls['email'].value
              }
              console.log('a', a);
              this.LoginService.sendemailconstact(a).subscribe(useri => {
                  this.form = this._formBuilder.group({
                      name: ['', Validators.required],
                      surname: ['', Validators.required],
                      sub: ['', Validators.required],
                      email: ['', [
                          Validators.required,
                          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
          
                  });
              });
         // }
      }
  }
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
