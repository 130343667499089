import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('Artopoulos:CitrusIT2020')
    }),
  }; 
  public userlogin:any;
  baseurl = 'https://citrushostnew.eu:1521/Service1.svc/';
  constructor(private http: HttpClient) { }

  sendemailconstact(Email) {
    return this.http.post(this.baseurl + 'sendemail/',
      {
        "email":Email.email,
        "subject":Email.subject,
        "emailaddress":Email.emailaddress
        
      }, this.httpOptions);
  }
  insertuser( Test) {
    return this.http.post(this.baseurl + 'insertuser/',
      {
        "ID":Test.ID,
        "Name":Test.Name,
        "OfficerName":Test.OfficerName,
        "CompanyName":Test.CompanyName,
        "Speciality":Test.Speciality,
        "Address":Test.Address,
        "Address2":Test.Address2,
        "Email":Test.Email,
        "Password":Test.Password,
        "Phone":Test.Phone,
        "flag":Test.flag,
        
        
      }, this.httpOptions);
  }
  activation(id,flag)
  {
    return this.http.get(this.baseurl + 'activation/id='+id+'/flag='+flag,this.httpOptions);

  }
  checkPass( check)
  {
    return this.http.post(this.baseurl + 'checkPass/',
    {
      "email":check.email,
      "password":check.password,
    }, this.httpOptions);
  }
  upload( docname, formData) {
    
    return this.http.post(this.baseurl + 'UploadFile/docname=' + docname + '/', formData, 
    { headers: new HttpHeaders({
     
      'Authorization': 'Basic ' + btoa('Citrus:Kitrosanthi13'),
     
    }),
      reportProgress: true,
      observe: 'events',
     
    }).pipe(
      catchError(this.errorMgmt)
    )   

  }
  CustomUploadFile( docname,ClientID,Typeofcase, formData) {
    
    return this.http.post(this.baseurl + 'CustomUploadFile/docname=' + docname + '/ClientID='+ClientID+'/Typeofcase='+Typeofcase, formData, 
    { headers: new HttpHeaders({
     
      'Authorization': 'Basic ' + btoa('Citrus:Kitrosanthi13'),
     
    }),
      reportProgress: true,
      observe: 'events',
     
    }).pipe(
      catchError(this.errorMgmt)
    )   

  }
  insetdoctodatabase(Docs)
  {
    return this.http.post(this.baseurl + 'insetdoctodatabase/',
    {
      "ID":Docs.ID,
      "ClientID":Docs.ClientID,
      "CaseIdentifier":Docs.CaseIdentifier,
      "DocName":Docs.DocName,
      "DataProtection":Docs.DataProtection,
      "Typeofcase":Docs.Typeofcase,
      "Date":Docs.Date,
      "Downloads":Docs.Downloads,
      "See":Docs.See,
    }, this.httpOptions);
  }
  returnClientDoc(id)
  {
    return this.http.get(this.baseurl + 'returnClientDoc/id='+id+'/',this.httpOptions);
  }

  deletedoc(id,user,docname)
  {
    return this.http.get(this.baseurl + 'deletedoc/id='+id+'/user='+user+'/docname='+docname+'/',this.httpOptions);
  }
  updatedowdoc(id,count,user,docname)
  {
    return this.http.get(this.baseurl + 'updatedowdoc/count='+count+'/id='+id+'/user='+user+'/docname='+docname+'/',this.httpOptions);
  }
  updateseedoc(id,count)
  {
    return this.http.get(this.baseurl + 'updateseedoc/count='+count+'/id='+id+'/',this.httpOptions);
  }
  downloadfile(id) {
   
    return this.http.get(this.baseurl + 'downloadfile/id=' + id + '/', { headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('Artopoulos:CitrusIT2020'),
     
    }), responseType: 'arraybuffer'});
  }
  returnClients() {
   
    return this.http.get(this.baseurl + 'returnClients/',this.httpOptions);

  }
  returnClientsiD(id) {
   
    return this.http.get(this.baseurl + 'returnClientsiD/id='+id+'/',this.httpOptions);

  }
  updateuser( Test) {
    return this.http.post(this.baseurl + 'updateuser/',
      {
        "ID":Test.ID,
        "Name":Test.Name,
        "OfficerName":Test.OfficerName,
        "CompanyName":Test.CompanyName,
        "Speciality":Test.Speciality,
        "Address":Test.Address,
        "Address2":Test.Address2,
        "Email":Test.Email,
        "Password":Test.Password,
        "Phone":Test.Phone,
        "flag":Test.flag,
        
        
      }, this.httpOptions);
  }
  deleteuser(id)
  {
    return this.http.get(this.baseurl + 'deleteuser/id='+id+'/',this.httpOptions);
  }
  forgotpass(email)
  {
    return this.http.get(this.baseurl + 'forgotpass/email='+email+'/',this.httpOptions);
  }

  returnNews()
  {
    return this.http.get(this.baseurl + 'returnNews/',this.httpOptions);
  }
  returnJournal()
  {
    return this.http.get(this.baseurl + 'returnJournal/',this.httpOptions);
  }
  insertNews( Test,flag) {
    return this.http.post(this.baseurl + 'insertNews/flag='+flag+'/',
      {
        "ID":Test.ID,
        "image":Test.image,
        "title":Test.title,
        "url":Test.url,
        "OrderNum":Test.OrderNum,
        
      }, this.httpOptions);
  }
  updateNews( Test,flag) {
    return this.http.post(this.baseurl + 'updateNews/flag='+flag+'/',
      {
        "ID":Test.ID,
        "image":Test.image,
        "title":Test.title,
        "url":Test.url,
        "OrderNum":Test.OrderNum,
        
      }, this.httpOptions);
  }
  deleteNews( Test,flag) {
    return this.http.post(this.baseurl + 'deleteNews/flag='+flag+'/',
      {
        "ID":Test.ID,
        "image":Test.image,
        "title":Test.title,
        "url":Test.url,
       
        
      }, this.httpOptions);
  }

  ////////////////////////////////
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
