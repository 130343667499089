import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../login.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  name = 'Set iframe source';
  url: string = "https://citrusit.eu/Trikkis";
  urlSafe: SafeResourceUrl;
  id: any = -1;
  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private LoginService: LoginService) { }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id != -1) {

    }
    else {
      alert('opa')
    }
  }

}
