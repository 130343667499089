<div class="main-banner">



    <div class="test121">
        <div class="aboutspace">
            <br><br> <br><br>
        </div>
        <p class="whyus2">3D Imaging</p>
        <p class="whyus3">Quick, accurate and non-invasive 3D reconstruction of the soft tissue morphology</p>

        <div class="spacebtn">
            <br> <br><br> <br><br>
        </div>
        <div>
            <div class="elementtS21">
                <img class="atS31" src="assets/imgs/imaging1.jpg" style="height: 480px; width: 895px;" />
                <div class="atS41">
                   
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black; font-weight: 700; font-size: 21px;">What is digital stereophotogrammetry?
                    </p>

                    <p class="ttS11">It is a very accurate [references] technique for digitizing the soft tissues and
                        textures of the face, breast and body in the three dimensions. As it is based on digital
                        photography, it is very quick (therefore avoiding motion artefacts) and 100% safe for both
                        adults and children, allowing multiple captures for long term monitoring, pre & post operative
                        comparisons etc. The acquired images can be merged onto a bone 3D scan (CT or CBCT) to obtain
                        highly accurate, fully textured combined 3D models of hard and soft tissues.
                    </p>

                </div>


            </div>

        </div>

        <div class="spacet1">
            <br> <br> <br> <br>
        </div>
        <div>
            <div class="elementtS11">
                <img class="atS11" src="assets/imgs/imaging2.jpg" style="height: 480px; width: 895px;"/>

                <div class="atS21">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">How does it work?
                    </p>
                    <p class="ttS11">
                        Exactly the same way as taking a few photos! The patient only has to attend for a few minutes
                        and have one or more photographs recorded, depending on the area of interest and intended
                        clinical application. The processed 3D data can be forwarded to you electronically in obj file
                        format.


                    </p>

                </div>
            </div>


        </div>


        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS41">
                <img class="atS71" src="assets/imgs/imaging3.jpg" style="height: 480px; width: 895px;"/>
                <div class="atS81">
                   
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">Which body parts can be imaged?
                    </p>
                    <p class="ttS11">Face and breast are the most commonly imaged body parts, but we can digitize any
                        other body part required, such as the skull, hand, foot, residual limp, torso and buttocks.

                    </p>
                </div>


            </div>

        </div>

        <div class="spacebtn">
            <br> <br><br> <br>
        </div>
        <div>
            <div class="elementtS31">
                <img class="atS51" src="assets/imgs/imaging4.jpg" style="height: 480px; width: 895px;"/>

                <div class="atS6N">
                    <hr style="border: none;
                    height: 2px;
                    color: #333;
                    background-color: #333;">
                    <p style="color: black;font-weight: 700;font-size: 21px;">What are the applications?
                    </p>
                    <p class="ttS11">
                        3D imaging of the soft tissues is valuable in every step of a patient's journey, from the
                        initial diagnosis to long term monitoring of soft tissue changes, and from 3D simulation to 3D
                        analysis of post operative outcomes. Further, 3D surface data can be used to fabricate body
                        prostheses, such as custom mastectomy prostheses, without a need to record conventional
                        impressions of the defect site, by 3D printing a replica of the tissues. Last but not least, you
                        can save a lot of time from treatment consent procedures and you can better protect your
                        patients and yourself with accurate, three dimensional record keeping!
                    </p>
                </div>
            </div>
            <br><br><br>

        </div>



    </div>
</div>