<div class="main-banner" style="height: 1200px;">
    <br><br><br><br><br><br>

    <div class="test" style="height: 500px;width: 100%;">
        <button style="margin-left: 25%;" color="primary" (click)="exit()">Log Out
            <mat-icon>exit_to_app</mat-icon>
        </button>
        <div class="columns3">
            <img class="column5img1" src="assets/imgs/login1.PNG" alt="Mountains">
        </div>
        <div class="cardadmin">
            <mat-tab-group dynamicHeight>
                <mat-tab label="Activated Users">
                    <div class="example-large-box mat-elevation-z4">
                        <div class="columns6">
                            <mat-form-field style="width: 100%;">
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
                            </mat-form-field>
                            <div style="width: 100%;   overflow: auto;">
                                <table mat-table [dataSource]="dataSource1" matSort class="mat-elevation-z8">

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID. </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.ID}}
                                        </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Name}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="CompanyName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Clinic / Company Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.CompanyName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Phone">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Phone}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="Email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Email}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTION </th>
                                        <mat-cell *matCellDef="let row">
                                            <section class="example-section">

                                            </section>
                                            <input style="color: black;" color="primary" mat-fab type="file"
                                                name="image" (change)="fileProgress($event,row)" />

                                        </mat-cell>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                </table>
                            </div>
                            <br>
                            <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="value"
                                [bufferValue]="bufferValue">
                            </mat-progress-bar>
                            <mat-tab-group  dynamicHeight>
                                <mat-tab *ngFor="let person of CaseIdentifier;let i = index"  [label]="person.Name">
                                    <mat-accordion>
                                        <mat-expansion-panel [expanded]="true">
                                            <!-- #enddocregion hide-toggle -->
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Existing Documents
                                                </mat-panel-title>
                                                <mat-panel-description>
                
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div style="width: 100%;   overflow: auto;">
                                                <table mat-table [dataSource]="person.datasource " matSort class="mat-elevation-z8">
                
                                                    <!-- Position Column -->
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID. </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                                                    </ng-container>
                
                                                    <!-- Name Column -->
                                                    <ng-container matColumnDef="Docname">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Docname </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.Docname}} </td>
                                                    </ng-container>
                
                                                    <!-- Weight Column -->
                                                    <ng-container matColumnDef="CaseIdentifier">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CaseIdentifier </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CaseIdentifier}} </td>
                                                    </ng-container>
                
                                                    <!-- Symbol Column -->
                                                    <ng-container matColumnDef="DataProtection">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DataProtection </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.DataProtection}} </td>
                                                    </ng-container>
                
                                                    <ng-container matColumnDef="Typeofcase">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Typeofcase </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.Typeofcase}} </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Date">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.Date}} </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Downloads">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Downloads </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.Downloads}} </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="See">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Read</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.See}} </td>
                                                    </ng-container>
                
                                                    <ng-container matColumnDef="actions">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTION </th>
                                                        <mat-cell *matCellDef="let row">
                
                                                            <button mat-mini-fab color="primary" (click)="dowload(row)">
                                                                <mat-icon>cloud_download</mat-icon>
                                                            </button>
                                                            <button mat-mini-fab color="primary" (click)="delete(row)">
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                        </mat-cell>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                </table>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </mat-tab>
                
                            </mat-tab-group>

                          
                            <br>
                            <mat-accordion>
                                <!-- #docregion basic-panel -->
                                <!-- #docregion hide-toggle -->
                                <mat-expansion-panel hideToggle>
                                    <!-- #enddocregion hide-toggle -->
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            PERSONAL INFORMATIONS
                                        </mat-panel-title>
                                        <mat-panel-description>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <form fxLayout="column" [formGroup]="form1" style=" padding : 30px;">
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinician's Name & Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text" formControlName="Name"
                                                    required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Data Protection Officer's Name &
                                                        Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="OfficerName">
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinic / Company Name</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="CompanyName" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Speciality</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Speciality" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Address</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Address Line 2</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address2" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Email</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Email" required>
                                                <mat-error>Email/Username!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Phone</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Phone" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Password</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Password" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                        </div>
                                        <br>
                                        <!--<mat-label class="registerbtn2" (click)="onSubmit1()"><b>Sumbit</b></mat-label>-->
                                    <mat-label class="registerbtn3" (click)="onSubmit2()"><b>Delete</b></mat-label>
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Email Confirmed Users">
                    <div class="example-large-box mat-elevation-z4">
                        <div class="columns6">
                            <mat-form-field style="width: 100%;">
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
                            </mat-form-field>
                            <div style="width: 100%;   overflow: auto;">
                                <table mat-table [dataSource]="dataSource11" matSort class="mat-elevation-z8">

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID. </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.ID}}
                                        </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Name}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="CompanyName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Clinic / Company Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.CompanyName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Phone">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Phone}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="Email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Email}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTION </th>
                                        <mat-cell *matCellDef="let row">
                                            <button mat-mini-fab color="primary" (click)="accept(row)">
                                                <mat-icon>done</mat-icon>
                                            </button>
                                            <button mat-mini-fab color="primary" (click)="reject(row)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </mat-cell>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                </table>
                            </div>
                            <br>
                            <mat-accordion>
                                <!-- #docregion basic-panel -->
                                <!-- #docregion hide-toggle -->
                                <mat-expansion-panel hideToggle>
                                    <!-- #enddocregion hide-toggle -->
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            PERSONAL INFORMATIONS
                                        </mat-panel-title>
                                        <mat-panel-description>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <form fxLayout="column" [formGroup]="form1" style=" padding : 30px;">
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinician's Name & Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text" formControlName="Name"
                                                    required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Data Protection Officer's Name &
                                                        Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="OfficerName">
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinic / Company Name</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="CompanyName" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Speciality</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Speciality" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Address</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Address Line 2</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address2" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Email</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Email" required>
                                                <mat-error>Email/Username!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Phone</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Phone" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Password</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Password" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                        </div>
                                        <br>
                                        <!--<mat-label class="registerbtn2" (click)="onSubmit1()"><b>Sumbit</b></mat-label>-->
                                    <mat-label class="registerbtn3" (click)="onSubmit2()"><b>Delete</b></mat-label>
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="No Email Confirmed Users">
                    <div class="example-large-box mat-elevation-z4">
                        <div class="columns6">
                            <mat-form-field style="width: 100%;">
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
                            </mat-form-field>
                            <div style="width: 100%;   overflow: auto;">
                                <table mat-table [dataSource]="dataSource111" matSort class="mat-elevation-z8">

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID. </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.ID}}
                                        </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Name}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="CompanyName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Clinic / Company Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.CompanyName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Phone">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Phone}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="Email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                        <td mat-cell *matCellDef="let element" (click)="selectcl(element.ID)">
                                            {{element.Email}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTION </th>
                                        <mat-cell *matCellDef="let row">
                                           
                                        </mat-cell>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                </table>
                            </div>
                            <br>
                            <mat-accordion>
                                <!-- #docregion basic-panel -->
                                <!-- #docregion hide-toggle -->
                                <mat-expansion-panel hideToggle>
                                    <!-- #enddocregion hide-toggle -->
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            PERSONAL INFORMATIONS
                                        </mat-panel-title>
                                        <mat-panel-description>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <form fxLayout="column" [formGroup]="form1" style=" padding : 30px;">
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinician's Name & Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text" formControlName="Name"
                                                    required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Data Protection Officer's Name &
                                                        Surname</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="OfficerName">
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Clinic / Company Name</b>
                                                </mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="CompanyName" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Speciality</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Speciality" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Address</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Address Line 2</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Address2" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%; padding: 10px;">
                                                <mat-label style="color: black;"><b>Email</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Email" required>
                                                <mat-error>Email/Username!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Phone</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Phone" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                                            fxLayoutGap="20px">
                                            <mat-form-field fxFlex style="width: 50%;padding: 10px;">
                                                <mat-label style="color: black;"><b>Password</b></mat-label>
                                                <input style="color: black;" matInput type="text"
                                                    formControlName="Password" required>
                                                <mat-error></mat-error>
                                            </mat-form-field>

                                        </div>
                                        <br>
                                        <!--<mat-label class="registerbtn2" (click)="onSubmit1()"><b>Sumbit</b></mat-label>
                                    <mat-label class="registerbtn3" (click)="onSubmit2()"><b>Delete</b></mat-label>-->
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>              
                <mat-tab label="News">
                    <div class="example-large-box1 mat-elevation-z4">
                        <button mat-fab aria-label="Example icon-button with a heart icon" (click)="newline(1)"
                            color="primary">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <button mat-fab aria-label="Example icon-button with a heart icon" (click)="deleteline(1)"
                            color="primary">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <table mat-table [dataSource]="dataSource2" matSort #sort="matSort">
                            <!-- Progress Column -->
                            <ng-container matColumnDef="ID" class="make-gold"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)"> {{row.ID}}
                                </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="image"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>image</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.image"
                                        (input)="updateinvoice($event.target.value,i,'image',1)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <!-- <td mat-cell *matCellDef="let row; let i = index;">
                                
                                <input matInput type="text" [ngModel]="row.image"
                                    (input)="updateinvoice($event.target.value,i,'image',1)" style="font-weight: bold;">
                                <div style="display: none;font-size: 10px;word-wrap: break-word;"> {{row.image }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="title"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> title</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.title"
                                        (input)="updateinvoice($event.target.value,i,'title',1)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <!-- <td mat-cell *matCellDef="let row; let i = index;">
                                <input matInput type="text" [ngModel]="row.title"
                                    (input)="updateinvoice($event.target.value,i,'title',1)" style="font-weight: bold;">
                                <div style="display: none;font-size: 10px;"> {{row.title }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="url"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>url</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.url"
                                        (input)="updateinvoice($event.target.value,i,'url',1)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="OrderNum"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>OrderNum</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.OrderNum"
                                        (input)="updateinvoice($event.target.value,i,'OrderNum',1)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="element-row"
                                [ngClass]="{'make-gold': row.Deleted == 1}"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns2;sticky: true"></tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Journal">
                    <div class="example-large-box1 mat-elevation-z4">
                        <button mat-fab aria-label="Example icon-button with a heart icon" (click)="newline(2)"
                            color="primary">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <button mat-fab aria-label="Example icon-button with a heart icon" (click)="deleteline(2)"
                            color="primary">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <table mat-table [dataSource]="dataSource3" matSort #sort="matSort">
                            <!-- Progress Column -->
                            <ng-container matColumnDef="ID" class="make-gold"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)"> {{row.ID}}
                                </td>
                                <!-- <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row.ID,2)"
                                style="width: 10px;">
                                <input matInput type="number" [ngModel]="row.ID" style="font-weight: bold;" disabled
                                    (click)="getline(row.ID,2)">
                                <div style="display: none; font-size: 10px;"> {{row.ID }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="image"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>image</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.image"
                                        (input)="updateinvoice($event.target.value,i,'image',2)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <!--   <td mat-cell *matCellDef="let row; let i = index;">
                                <input matInput type="text" [ngModel]="row.image"
                                    (input)="updateinvoice($event.target.value,i,'image',2)" style="font-weight: bold;">
                                <div style="display: none;font-size: 10px;word-wrap: break-word;"> {{row.image }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="title"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> title</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.title"
                                        (input)="updateinvoice($event.target.value,i,'title',2)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <!--  <td mat-cell *matCellDef="let row; let i = index;">
                                <input matInput type="text" [ngModel]="row.title"
                                    (input)="updateinvoice($event.target.value,i,'title',2)" style="font-weight: bold;">
                                <div style="display: none;font-size: 10px;"> {{row.title }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="url"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>url</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.url"
                                        (input)="updateinvoice($event.target.value,i,'url',2)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <!-- <td mat-cell *matCellDef="let row; let i = index;"><input matInput type="text"
                                    [ngModel]="row.url" (input)="updateinvoice($event.target.value,i,'url',2)"
                                    style="font-weight: bold;">
                                <div style="display: none;"> {{row.url }}</div>
                            </td>-->
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <ng-container matColumnDef="OrderNum"> 
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>OrderNum</th>
                                <td mat-cell *matCellDef="let row; let i = index;" (click)="getline(row)">
                                    <textarea rows="8" cols="10" matInput type="text" [ngModel]="row.OrderNum"
                                        (input)="updateinvoice($event.target.value,i,'OrderNum',2)"
                                        style="font-weight: bold;"></textarea>
                                </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns3;" class="element-row"
                                [ngClass]="{'make-gold': row.Deleted == 1}"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns3;sticky: true"></tr>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="columns3">
            <img class="column5img2" src="assets/imgs/login2.png" alt="Mountains">
        </div>

    </div>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br><br><br><br><br>
</div>